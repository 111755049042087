import React from 'react'
import { isShowLoading } from 'ui/screens/Patient/Browse/StreamResults/StreamResult'
import Plot from 'ui/components/Plot'
import StreamVisualization from './StreamVisualization'
import { useStreamApi } from 'domains/streamApi'
import { useDownloadOptions } from './downloadOptions'

/**
 * Heart Rate data
 * @returns {React.Component} HeartRate component
 */
const HeartRate = ({
  device,
  patient,
  query,
  title,
  tStart,
  tEnd,
  updateTimeRange,
  timezoneNameEnabled,
}) => {
  const { data, error, showLoading, revision } = useStreamApi({
    url: 'heartrate.json',
    params: {
      patient_id: patient.id,
      device_id: device.id,
      start_time: tStart,
      end_time: tEnd,
      ...query,
      interpolation: 'null',
      frequency: Math.min(query.frequency, 0.1),
    },
    isShowLoading,
  })
  const downloadOptions = useDownloadOptions({
    streamName: 'heartrate',
    endpointName: 'heartrate',
    patientID: patient.id,
    deviceID: device.id,
    startTime: tStart,
    endTime: tEnd,
    query: query,
  })

  return (
    <StreamVisualization
      height={240}
      device={device}
      downloadOptions={downloadOptions}
      error={error}
      showLoading={showLoading}
      patient={patient}
      title={title}
      tStart={tStart}
      tEnd={tEnd}
      updateTimeRange={updateTimeRange}
      timezoneNameEnabled={timezoneNameEnabled}
    >
      {(defaults) => (
        <Plot
          className={defaults.className}
          config={defaults.config}
          data={[
            {
              hovertemplate: `
                  <b>%{x}</b>
                  <br>
                  <b>%{y}</b>
                `,
              line: {
                width: data.result.cardinality < 2000 ? 1 : 2,
              },
              mode: 'lines',
              name: 'BPM',
              type: 'scatter',
              visible: true,
              x: data.result.time,
              y: data.result.bpm,
            },
          ]}
          layout={{
            ...defaults.layout,
            legend: { orientation: 'h', y: 1.1 },
            yaxis: {
              ...defaults.layout.yaxis,
              ticksuffix: 'BPM',
            },
          }}
          onClick={defaults.onClick}
          onInitialized={defaults.onInitialized}
          onRelayout={defaults.onRelayout}
          revision={revision}
          useResizeHandler={defaults.useResizeHandler}
        />
      )}
    </StreamVisualization>
  )
}

export default HeartRate
