import { memo } from 'react'
import { colors } from 'theme/colors'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  Tooltip,
  ZAxis,
} from 'recharts'
import { Box } from '@mui/material'
import { DEBOUNCE_DELAY_DEFAULT } from './consts'

const BubbleChart = ({
  data,
  legendItem,
  legend,
  height = 60,
  tooltip,
  animateBubbles,
  chartId = 'bubble-chart',
}) => {
  const STYLES = {
    chartContainer: {
      height,
      width: '100%',
      '& .recharts-legend-item-text': {
        color: `${colors.GREY[700]} !important`, // overriding js
      },
    },
  }

  const range = [0, 100]

  return (
    <Box
      sx={STYLES.chartContainer}
      data-cy="bubble-chart"
      data-chart-id={chartId}
    >
      <ResponsiveContainer
        debounce={DEBOUNCE_DELAY_DEFAULT}
        width="100%"
        height={height}
      >
        <ScatterChart
          margin={{
            top: 0,
            right: 16,
            left: 10,
            bottom: 5,
          }}
        >
          <XAxis
            type="category"
            dataKey="name"
            name="hour"
            interval={11}
            tickMargin={8}
            fontSize={10}
            axisLine={{ stroke: colors.GREY[400] }}
            tickLine={{ stroke: colors.TREMOR[300] }}
          />
          <YAxis
            type="number"
            dataKey="index"
            hide
            tick={false}
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            fontSize={10}
          />
          <ZAxis type="number" dataKey="value" range={range} />
          {legend && (
            <Legend
              verticalAlign="top"
              align="right"
              iconType="circle"
              iconSize={10}
              wrapperStyle={{ top: -8, fontSize: 10 }}
            />
          )}
          {tooltip && <Tooltip content={tooltip} />}
          <Scatter
            name={legendItem.name}
            data={data}
            fill={legendItem.color}
            isAnimationActive={animateBubbles}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default memo(BubbleChart)
