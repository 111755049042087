const searchPatientsSlice = (set) => ({
  moreSearchResultsAvailable: false,
  setMoreSearchResultsAvailable: (available) =>
    set(() => ({
      moreSearchResultsAvailable: available,
    })),
  resetSearchPatients: () =>
    set(() => ({
      searchPatientsError: null,
      searchPatientsLoading: null,
      searchPatientsResults: [],
      searchPatientsInputFields: { firstName: '', lastName: '', email: '' },
    })),
  searchPatientsError: null,
  searchPatientsExecuted: false,
  setSearchPatientsExecuted: (executed) =>
    set(() => ({
      searchPatientsExecuted: executed,
    })),
  searchPatientsLoading: null,
  searchPatientsResults: [],
  searchPatientsInputFields: { firstName: '', lastName: '', email: '' },
  setSearchPatientsInputFields: (input, field) =>
    set((state) => ({
      searchPatientsInputFields: {
        ...state.searchPatientsInputFields,
        [field]: input,
      },
    })),
})

export default searchPatientsSlice
