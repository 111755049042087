import { gql } from '@apollo/client'

export const PATIENT_SEARCH = gql`
  query patientSearch($email: String, $firstName: String, $lastName: String) {
    patientSearch(email: $email, firstName: $firstName, lastName: $lastName) {
      patients {
        id
        codeName
        identifiableInfo {
          email
          firstName
          lastName
          realName
        }
      }
      moreResults
    }
  }
`
