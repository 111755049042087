import axios from 'axios'
import moment from 'moment/moment'

import { NEXT_PAGE_TOKEN } from './consts'

/**
 * Call the Stream API, with authentication handling
 * @param url
 * @param params
 * @param axiosOptions
 * @param timezone
 * @param streamApiClient
 * @param method
 * @return {Promise<*>}
 */
export const callApi = async ({
  url,
  params,
  headers,
  getHeaders,
  handleUnauthenticated,
  streamApiClient,
  axiosOptions,
  method,
}) => {
  if (!headers) {
    try {
      headers = await getHeaders()
    } catch (error) {
      handleUnauthenticated(error)
      throw error
    }
  }

  if (method === 'POST') {
    try {
      // TODO: remove timezone param delete after timezoneNameEnabled flag is removed
      delete params.timezone
      delete params.timezone_name
      const arrParams = Object.values(params)
      headers['Content-Type'] = 'application/json'
      const response = await streamApiClient.post(url, arrParams, {
        headers: headers,
        ...axiosOptions,
      })
      return response ? response.data : response
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  } else {
    try {
      const response = await streamApiClient.get(url, {
        headers,
        params,
        ...axiosOptions,
      })
      const nextPageTokenResponse =
        response?.headers?.[NEXT_PAGE_TOKEN.responseHeader]

      return {
        ...(response?.data || response),
        ...(nextPageTokenResponse && {
          [NEXT_PAGE_TOKEN.key]: nextPageTokenResponse,
        }),
      }
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  }
}

const TARGET_POINTS_PER_CHART_MIN = 1000
const TARGET_POINTS_PER_CHART_MAX = 4000
const DURATION_THRESHOLD_LOW = 5
const DURATION_THRESHOLD_HIGH = 60 * 5

export const getTargetCardinality = (durationSeconds) => {
  if (durationSeconds <= DURATION_THRESHOLD_LOW)
    return TARGET_POINTS_PER_CHART_MIN
  if (durationSeconds >= DURATION_THRESHOLD_HIGH)
    return TARGET_POINTS_PER_CHART_MAX

  const spread = TARGET_POINTS_PER_CHART_MAX - TARGET_POINTS_PER_CHART_MIN
  const thresholdRange = DURATION_THRESHOLD_HIGH - DURATION_THRESHOLD_LOW
  const percentScale =
    (durationSeconds - DURATION_THRESHOLD_LOW) / thresholdRange
  return TARGET_POINTS_PER_CHART_MIN + percentScale * spread
}

export const getFrequency = (startTime, endTime) => {
  const durationSeconds =
    (moment(endTime).valueOf() - moment(startTime).valueOf()) / 1000
  // As users zoom out towards the DURATION_THRESHOLD_HIGH, we get closer to the max of 4k data points
  // As users zoom in towards the DURATION_THRESHOLD_LOW, we got closer to the min of 2k data points
  // This should give the impression of being "zoomed out" vs "zoomed in"
  const targetPointCount = getTargetCardinality(durationSeconds)
  return targetPointCount / durationSeconds
}
export const getDownloadParams = (jsonParams) => {
  // remove frequency and resolution - always download without downsampling
  const { frequency, resolution, ...rest } = jsonParams
  return rest
}
