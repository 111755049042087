import { Fragment, memo, useContext, useEffect, useRef, useState } from 'react'
import { arrayOf, number, object, shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFlags } from 'domains/launchdarkly/hooks'
import { MINIMUM_INTENSITY } from './consts'
import ChartLoading from 'ui/components/ChartLoading'
import EmptyResults from 'ui/components/EmptyResults'
import Plot from 'ui/components/Plot'
import { extractParameterValue } from 'domains/streamApi/hooksV2'
import { returnDataForSpecifiedDate } from './helpers/helpers'
import { formatDataForPlotly } from './helpers/layout'
import { formatSleepData } from './helpers/sleep'
import useSleepStreamData from 'ui/hooks/rawData/useSleepStreamData'
import useTremorAndDyskinesiaData from 'ui/hooks/rawData/useTremorAndDyskinesiaData'
import { PatientContext } from 'ui/contexts'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { formatV2MotorSymptomsDataForPlotting } from './helpers/motorSymptoms'
import PlotHover from './PlotHover'

const useStyles = makeStyles(() => ({
  plot: {
    width: '100%',
  },
}))

const DailyPlot = ({ formattedData, intensityRange, startDate }) => {
  const { timezoneNameEnabled } = useFlags()
  const { selectedTimezone } = useContext(DateRangeContext)
  const classes = useStyles()

  const [plotted, setPlotted] = useState(false)
  const plotRef = useRef(null)
  const onPurge = (_, graphDiv) => {
    if (typeof graphDiv.removeAllListeners !== 'undefined') {
      graphDiv.removeAllListeners('plotly_hover')
      graphDiv.removeAllListeners('plotly_unhover')
    }
    setPlotted(false)
  }
  const onAfterPlot = () => setPlotted(true)

  const { id: patientId, deviceList } = useContext(PatientContext)

  const sleepDataFromStreamAPI = useSleepStreamData(startDate)
  const [sleepDataToVisualize, setSleepDataToVisualize] = useState([])

  const {
    hasData: hasMotorData,
    data: motorData,
    isLoading: motorDataIsLoading,
  } = useTremorAndDyskinesiaData(
    patientId,
    deviceList?.devices || [],
    startDate,
  )
  const [motorDataToVisualize, setMotorDataToVisualize] = useState([])

  const [dataFormattedForPlotly, setDataFormattedForPlotly] = useState([])

  const {
    isLoading: sleepDataFromApiIsLoading,
    hasData: sleepDataFromApiHasData,
    data: sleepDataFromApiData,
  } = sleepDataFromStreamAPI || {}

  useEffect(() => {
    if (sleepDataFromApiHasData) {
      setSleepDataToVisualize(sleepDataFromApiData)
    }
  }, [sleepDataFromApiData, sleepDataFromApiHasData])

  useEffect(() => {
    if (hasMotorData) {
      const motorDataForPlotly = formatV2MotorSymptomsDataForPlotting(
        motorData,
        selectedTimezone,
      )
      setMotorDataToVisualize(motorDataForPlotly)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMotorData, selectedTimezone])

  useEffect(() => {
    const { max, min } = intensityRange

    const formattedDataForCurrentDate =
      (max && min >= MINIMUM_INTENSITY && formattedData) || []

    if (sleepDataToVisualize) {
      const formattedSleepData = sleepDataToVisualize.map((streamData) => {
        const sleepStatus = extractParameterValue(
          streamData.streamDetails.parameters,
          'sleep_status',
        )

        return formatSleepData(streamData.data, sleepStatus, selectedTimezone)
      })

      /*  TODO: This is a hack to account for current timezone issues in the app.😔
          Because the timezone offset sent to the BE is the offset for the user's current day and not the offset for the date for which we are requesting data,
          we run into scenarios where we get data that should be associated with a different day,
          and which results in the app breaking.
          E.g. CLI-1140 (https://runelabs.atlassian.net/jira/software/projects/CLI/boards/5?selectedIssue=CLI-1140) has an example of a request for 2023-01-21 data which returns data for 2023-01-22.
          However, part of that response does indeed belong to 2023-01-21 Pacific Time. We're using the wrong offset in the request.
          The long term plan is to fix the timezone offset issue.
          In the meantime, it was decided that it's best to have sleep data, tremor and dyskinesia, etc. align (i.e., indicate to the user whether or not they happened at the same time),
          rather than have sleep data use the correct timezone offset and diverge from other data by one hour.
      */

      /*  TODO: After timezoneNameEnabled flag is on for all users, remove above text re: hack.
          Below function will remain in place to prevent plotly from crashing when data extends
          beyond the given date or with undefined data.
      */
      const formattedSleepDataForCurrentDay = returnDataForSpecifiedDate({
        data: formattedSleepData,
        date: startDate,
      })
      // End of Hack.
      const sleepDataForCurrentDayCombined = [].concat(
        ...formattedSleepDataForCurrentDay,
      )

      const allFormattedData = [
        ...formattedDataForCurrentDate,
        ...sleepDataForCurrentDayCombined,
        ...motorDataToVisualize,
      ]

      setDataFormattedForPlotly(
        formatDataForPlotly(allFormattedData, startDate, timezoneNameEnabled),
      )
    }

    if (!sleepDataToVisualize) {
      setDataFormattedForPlotly(
        formatDataForPlotly(
          formattedDataForCurrentDate.concat(motorDataToVisualize),
          startDate,
          timezoneNameEnabled,
        ),
      )
    }
  }, [
    formattedData,
    intensityRange,
    sleepDataToVisualize,
    motorDataToVisualize,
    startDate,
    timezoneNameEnabled,
    selectedTimezone,
  ])

  if (
    typeof formattedData === 'undefined' ||
    (formattedData?.length && !dataFormattedForPlotly?.data?.length) ||
    sleepDataFromApiIsLoading ||
    motorDataIsLoading
  ) {
    return <ChartLoading height={150} />
  }

  if (!dataFormattedForPlotly?.data?.length) {
    return <EmptyResults message="No data logged" />
  }

  return (
    <Fragment>
      <Plot
        className={classes.plot}
        config={{
          autosize: true,
          responsive: true,
          displayModeBar: false,
          doubleClick: false,
          scrollZoom: false,
        }}
        useResizeHandler={true}
        data={dataFormattedForPlotly.data}
        layout={dataFormattedForPlotly.layout}
        ref={plotRef}
        onPurge={onPurge}
        onAfterPlot={onAfterPlot}
      />
      <PlotHover elementRef={plotRef} plotted={plotted} />
    </Fragment>
  )
}

DailyPlot.propTypes = {
  startDate: string.isRequired,
  formattedData: arrayOf(
    shape({
      plotData: shape({
        hoverLabel: shape({
          bgcolor: string,
          bordercolor: string,
          font: object,
        }),
        marker: shape({ opacity: number, symbol: number }),
        name: string,
        text: arrayOf(object),
        x: arrayOf(string),
        y: arrayOf(string),
      }),
      intensity: number,
    }),
  ),
  intensityRange: shape({
    max: number,
    min: number,
  }),
}

DailyPlot.defaultProps = {}

export default memo(DailyPlot)
