import { useRef } from 'react'
import * as MUI from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Button } from 'ui/components/Button'
import useBoundStore from 'domains/zustand/store'

/**
 * A search input component for searching patients by identifiable info.
 * @returns {JSX.Element} The rendered component.
 */
const PatientSearchPhiFields = () => {
  const resetSearchPatients = useBoundStore(
    (state) => state.resetSearchPatients,
  )
  const searchPatientsInputFields = useBoundStore(
    (state) => state.searchPatientsInputFields,
  )
  const setSearchPatientsInputFields = useBoundStore(
    (state) => state.setSearchPatientsInputFields,
  )

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)

  const getInputProps = (inputField, ref) => ({
    endAdornment: (
      <MUI.InputAdornment position="end">
        <MUI.IconButton
          onClick={() => {
            setSearchPatientsInputFields('', inputField)
            ref.current.focus()
          }}
          data-cy={`clear-search-patients-${inputField}`}
        >
          <ClearIcon />
        </MUI.IconButton>
      </MUI.InputAdornment>
    ),
    startAdornment: (
      <MUI.InputAdornment position="start">
        <SearchIcon />
      </MUI.InputAdornment>
    ),
  })

  return (
    <>
      <MUI.TextField
        inputRef={firstNameRef}
        value={searchPatientsInputFields.firstName}
        placeholder="First name"
        InputProps={getInputProps('firstName', firstNameRef)}
        variant="outlined"
        onChange={(event) =>
          setSearchPatientsInputFields(event.target.value, 'firstName')
        }
        data-cy="search-patients-first-name"
      />
      <MUI.TextField
        inputRef={lastNameRef}
        value={searchPatientsInputFields.lastName}
        placeholder="Last name"
        InputProps={getInputProps('lastName', lastNameRef)}
        variant="outlined"
        onChange={(event) =>
          setSearchPatientsInputFields(event.target.value, 'lastName')
        }
        data-cy="search-patients-last-name"
      />
      <MUI.TextField
        inputRef={emailRef}
        value={searchPatientsInputFields.email}
        placeholder="Email"
        InputProps={getInputProps('email', emailRef)}
        variant="outlined"
        onChange={(event) =>
          setSearchPatientsInputFields(event.target.value, 'email')
        }
        data-cy="search-patients-email"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          resetSearchPatients()
          firstNameRef.current.focus()
        }}
      >
        Reset
      </Button>
    </>
  )
}
export default PatientSearchPhiFields
