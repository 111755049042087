import { useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import * as MUI from '@mui/material'
import { colors } from 'theme/colors'
import { Stack, Table, Text } from 'ui/baseComponents'
import { WEEKLY_VIEW_TABLE_HEADINGS } from 'ui/clinicianScreens/Patient/WeeklyView/consts'
import {
  getMedicationsByWeek,
  getMobilityMetricsByWeek,
  getNotesByWeek,
  getSymptomsByWeek,
} from 'ui/clinicianScreens/Patient/WeeklyView/helpers'
import WeeklyViewDatePicker from 'ui/clinicianScreens/Patient/WeeklyView/WeeklyViewDatePicker'
import { PatientContext } from 'ui/contexts'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import { AGGREGATE_WINDOW_METRIC_TYPES } from 'ui/hooks/consts'
import useAggregateWindow from 'ui/hooks/summaryAggregates/useAggregateWindow'
import { reFetchEventList } from 'ui/screens/Patient/queries'
import TabActionableHeader from 'ui/templates/TabActionableHeader'
import { getTimestampEndOfDay, getTimestampStartOfDay } from 'utilities/time'
import usePatientEvents from 'ui/hooks/usePatientEvents'

const WeeklyView = () => {
  const [weeklyMobilityData, setWeeklyMobilityData] = useState([])
  const [loadingRefetchEvents, setLoadingRefetchEvents] = useState(false)
  const [showLoadingState, setShowLoadingState] = useState(true)
  const patientContext = useContext(PatientContext)

  const { dateRanges, selectedTimezone } = useContext(DateRangeContext)

  const { start: weeklyViewStartDate, end: weeklyViewEndDate } =
    dateRanges[DATE_RANGE.weeklyViewPeriod.key] || {}

  const startTime = getTimestampStartOfDay(
    weeklyViewStartDate,
    selectedTimezone,
  )
  const endTime = getTimestampEndOfDay(weeklyViewEndDate, selectedTimezone)

  const { dataEvents, loadingEvents, fetchMore } = usePatientEvents(
    patientContext.id,
    startTime,
    endTime,
  )

  useEffect(() => {
    const { endCursor } = dataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      setLoadingRefetchEvents(true)
      reFetchEventList(fetchMore, endCursor)
    } else {
      setLoadingRefetchEvents(false)
    }
  }, [dataEvents, fetchMore])

  const eventList = useMemo(
    () => dataEvents?.patient?.eventList?.events || [],
    [dataEvents],
  )

  const weeksArray = Array(8).fill({})
  const weekStartDates = weeksArray?.map((_, index) =>
    moment(weeklyViewStartDate).add(7 * index, 'days'),
  )

  const notesByWeek = getNotesByWeek(
    weekStartDates,
    eventList,
    selectedTimezone,
  )
  const symptomCountsByWeek = getSymptomsByWeek(weekStartDates, eventList)
  const medicationsByWeek = getMedicationsByWeek(weekStartDates, eventList)

  const { data: aggregateWindowData, loading: loadingAggregateWindow } =
    useAggregateWindow(weeklyViewStartDate, weeklyViewEndDate) || {}

  const {
    stepLength = [],
    walkingSpeed = [],
    doubleSupport = [],
  } = getMobilityMetricsByWeek(aggregateWindowData) || {}

  const hasStepLengthData = stepLength?.some((data) => data)
  const hasWalkingSpeedData = walkingSpeed?.some((data) => data)
  const hasDoubleSupportData = doubleSupport?.some((data) => data)
  const hasMobilityData =
    hasStepLengthData || hasWalkingSpeedData || hasDoubleSupportData
  const hasNotesData = notesByWeek.some((data) => data.length)
  const hasSymptomsData = symptomCountsByWeek.some(
    (data) => Object.keys(data).length,
  )
  const hasMedicationData = medicationsByWeek.some(
    (data) => Object.keys(data).length,
  )
  const hasData =
    hasMobilityData || hasNotesData || hasSymptomsData || hasMedicationData
  const isLoading =
    loadingEvents || loadingAggregateWindow || loadingRefetchEvents

  useEffect(() => {
    if (isLoading) {
      setShowLoadingState(true)
    } else {
      setShowLoadingState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!loadingAggregateWindow && hasMobilityData) {
      weeksArray.forEach((_, index) => {
        setWeeklyMobilityData((prevState) => [
          ...prevState,
          {
            [AGGREGATE_WINDOW_METRIC_TYPES.walkingSpeed]: walkingSpeed[index]
              ? `${walkingSpeed[index]?.toFixed(2)}m/s`
              : '',
            [AGGREGATE_WINDOW_METRIC_TYPES.stepLength]: stepLength[index]
              ? `${stepLength[index]?.toFixed(2)}m`
              : '',
            [AGGREGATE_WINDOW_METRIC_TYPES.doubleSupport]: doubleSupport[index]
              ? `${doubleSupport[index]?.toFixed(2)}%`
              : '',
          },
        ])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMobilityData, loadingAggregateWindow])

  const headings = [
    {
      key: WEEKLY_VIEW_TABLE_HEADINGS.date,
      value: WEEKLY_VIEW_TABLE_HEADINGS.date,
    },
    {
      key: WEEKLY_VIEW_TABLE_HEADINGS.pros,
      value: WEEKLY_VIEW_TABLE_HEADINGS.pros,
    },
    {
      key: WEEKLY_VIEW_TABLE_HEADINGS.stepLength,
      value: WEEKLY_VIEW_TABLE_HEADINGS.stepLength,
    },
    {
      key: WEEKLY_VIEW_TABLE_HEADINGS.walkingSpeed,
      value: WEEKLY_VIEW_TABLE_HEADINGS.walkingSpeed,
    },
    {
      key: WEEKLY_VIEW_TABLE_HEADINGS.doubleSupport,
      value: WEEKLY_VIEW_TABLE_HEADINGS.doubleSupport,
    },
    {
      key: WEEKLY_VIEW_TABLE_HEADINGS.medication,
      value: WEEKLY_VIEW_TABLE_HEADINGS.medication,
    },
    {
      key: WEEKLY_VIEW_TABLE_HEADINGS.notes,
      value: WEEKLY_VIEW_TABLE_HEADINGS.notes,
    },
  ]

  const rows = weeksArray?.map((_, index) => [
    {
      key: `${WEEKLY_VIEW_TABLE_HEADINGS.date}-cell`,
      value: (
        <>
          <Text variant="body14" component="p">
            {weekStartDates[index].format('MM/DD/YYYY')}
          </Text>
          <Text variant="body14" sx={{ color: colors.GREY[500] }}>
            Week {index + 1}
          </Text>
        </>
      ),
    },
    {
      key: `${WEEKLY_VIEW_TABLE_HEADINGS.pros}-cell`,
      value: Object.entries(symptomCountsByWeek[index]).map(([name, count]) => (
        <Text
          key={name}
          variant="body14B"
          component="p"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {name}:{' '}
          <Text variant="body14B" sx={{ color: colors.ORANGE[500] }}>
            {count}
          </Text>
        </Text>
      )),
    },
    {
      key: `${WEEKLY_VIEW_TABLE_HEADINGS.stepLength}-cell`,
      value: (
        <Text variant="body14">
          {
            weeklyMobilityData[index]?.[
              AGGREGATE_WINDOW_METRIC_TYPES.stepLength
            ]
          }
        </Text>
      ),
    },
    {
      key: `${WEEKLY_VIEW_TABLE_HEADINGS.walkingSpeed}-cell`,
      value: (
        <Text variant="body14">
          {
            weeklyMobilityData[index]?.[
              AGGREGATE_WINDOW_METRIC_TYPES.walkingSpeed
            ]
          }
        </Text>
      ),
    },
    {
      key: `${WEEKLY_VIEW_TABLE_HEADINGS.doubleSupport}-cell`,
      value: (
        <Text variant="body14">
          {
            weeklyMobilityData[index]?.[
              AGGREGATE_WINDOW_METRIC_TYPES.doubleSupport
            ]
          }
        </Text>
      ),
    },
    {
      key: `${WEEKLY_VIEW_TABLE_HEADINGS.medication}-cell`,
      value: Object.entries(medicationsByWeek[index])
        .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
        .map(([medKey, med]) => {
          const { name, dosage, quantity, unit } = med
          const prevWeek = Object.keys(medicationsByWeek[index - 1] || {})
          const isExistingMedication =
            index === 0 || prevWeek?.find((prevMed) => prevMed === medKey)

          return (
            <Text
              key={name}
              variant={isExistingMedication ? 'body14' : 'body14B'}
              component="p"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {name}: {dosage}
              {quantity ? ` x ${quantity}` : ''}
              {unit}
            </Text>
          )
        }),
    },
    {
      key: `${WEEKLY_VIEW_TABLE_HEADINGS.notes}-cell`,
      value: (
        <Stack>
          {notesByWeek[index].map((note, noteIndex) => (
            <Text
              key={`${note.noteDate}${noteIndex}`}
              variant="body14B"
              component="p"
            >
              {note.noteDate}: <Text variant="body14">{note.noteContent}</Text>
            </Text>
          ))}
        </Stack>
      ),
      sx: { maxWidth: 500 },
    },
  ])

  return (
    <>
      <TabActionableHeader>
        <Stack sx={{ flex: 1 }}>
          <WeeklyViewDatePicker />
        </Stack>
      </TabActionableHeader>
      {showLoadingState ? (
        <Stack
          alignItems="center"
          sx={{ padding: '2rem' }}
          data-cy="weekly-view-loading"
        >
          <Text variant="body14">Loading...</Text>
          <MUI.CircularProgress size="5rem" />
        </Stack>
      ) : (
        <Stack sx={{ padding: '2rem' }} data-cy="weekly-view-container">
          {hasData ? (
            <Table
              {...{ headings, rows, 'data-cy': 'weekly-view-table' }}
            ></Table>
          ) : (
            <Text variant="body14" data-cy="weekly-view-no-data">
              No data available
            </Text>
          )}
        </Stack>
      )}
    </>
  )
}

export default WeeklyView
