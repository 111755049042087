import {
  STRIVEPD_PRIMARY_1100,
  STRIVEPD_PRIMARY_1000,
} from '../../../theme/colors'

export const SUBMIT_ROW = {
  marginTop: '1rem',
}

export const SUBMIT_BUTTON = {
  background: STRIVEPD_PRIMARY_1000,
  '&:hover': {
    background: STRIVEPD_PRIMARY_1100,
  },
}

export const INCORRECT_PASSWORD_ERROR = 'Incorrect password'
export const INCORRECT_EMAIL_ERROR = 'This email does not exist'
export const RESET_PASSWORD_ERROR =
  'We are unable to reset your password at this time. Contact your account administrator and request that they send you another invite.'
export const INVALID_VERIFICATION_CODE_ERROR = 'Invalid verification code'
export const INVALID_PASSWORD_ERROR = 'Invalid password'

export const TERMS_AND_PRIVACY_NOTICE_AGREEMENT = {
  existingUser: 'By using these services, I agree to the Rune Labs',
  newUser: 'By confirming my account, I agree to the Rune Labs',
}

export const COGNITO_ATTR_DEFAULT_LOGIN_PORTAL = 'custom:default_login_portal'
export const DEFAULT_LOGIN_PORTAL_STRIVE_STUDY = 'STRIVE_STUDY'
