import { useMemo } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import {
  STYLES,
  TABLE_HEADERS,
} from 'ui/screens/Patients/PatientList/PatientTable/consts'
import {
  PatientTableLoading,
  PatientTableEmpty,
  PatientTableError,
  TableCellLinkToPatientSettings,
  TableCellHeader,
  TableCellPatientName,
} from 'ui/screens/Patients/PatientList/PatientTable'
import { calculateShowingConditions } from 'ui/screens/Patients/PatientList/PatientTable/helpers'
import { useIsAdmin, usePatientList } from 'ui/hooks'
import { PATIENT_LIST_LIMIT } from 'ui/hooks/usePatientList'
import useBoundStore from 'domains/zustand/store'

/**
 * The patient table component for the researcher portal.
 * @returns {JSX.Element} The rendered component.
 */
const PatientTableResearcherPortal = () => {
  const errorPatientList = useBoundStore((state) => state.errorPatientList)
  const loadingPatientList = useBoundStore((state) => state.loadingPatientList)
  const patients = useBoundStore((state) => state.patients)
  const showAllPatients = useBoundStore((state) => state.showAllPatients)
  const { admin } = useIsAdmin()
  const patientListLimit = !showAllPatients
    ? PATIENT_LIST_LIMIT.recent
    : undefined // eslint-disable-line no-undefined
  usePatientList({
    limit: patientListLimit,
    fetchAll: !patientListLimit,
  })

  const SHOWING_CONDITIONS = useMemo(
    () =>
      calculateShowingConditions({
        loading: loadingPatientList,
        error: errorPatientList,
        patients,
      }),
    [loadingPatientList, errorPatientList, patients],
  )

  return (
    <Table
      aria-label="Patient List"
      data-cy="patient-table-researcher-portal"
      data-testid="patient-table-researcher-portal"
    >
      <TableHead>
        <TableRow>
          {SHOWING_CONDITIONS.always && (
            <TableCellHeader>{TABLE_HEADERS.patient}</TableCellHeader>
          )}
          {admin && (
            <TableCellHeader sx={STYLES.iconCell}>
              {TABLE_HEADERS.settings}
            </TableCellHeader>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loadingPatientList && <PatientTableLoading />}
        {errorPatientList && <PatientTableError error={errorPatientList} />}
        {SHOWING_CONDITIONS.noPatients && <PatientTableEmpty />}
        {SHOWING_CONDITIONS.allPatientsLoaded &&
          patients.map((patient) => {
            const { id, preComputedName } = patient

            return (
              <TableRow key={id} data-cy={`patient-row-${id}`}>
                <TableCellPatientName
                  patientId={id}
                  patientName={preComputedName}
                />
                {admin && <TableCellLinkToPatientSettings patientId={id} />}
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}
export default PatientTableResearcherPortal
