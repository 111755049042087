import { RUNE_GRAY_700 } from 'theme/colors'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'
import {
  SECTION_TITLE,
  TRENDS_TOOLTIP_TEXT,
} from 'ui/clinicianScreens/Patient/consts'
import { InformationalIconTooltip } from 'ui/components/InformationalIconTooltip'
import { StyledTitle } from 'ui/baseComponents/StyledTitle'
import Box from '@mui/material/Box'
import Subheading from '../Subheading'
import { SleepMetrics } from './SleepMetrics'
import { MobilityMetrics } from './MobilityMetrics'
import { MotorMetrics } from './MotorMetrics'
import { OverviewPatientNotes } from './OverviewPatientNotes'
import { OverviewPatientSymptoms } from './OverviewPatientSymptoms'

const STYLES = {
  trends: {
    marginLeft: '1.5em',
  },
}

const OverviewMainContent = () => (
  <Stack spacing={0}>
    <Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        <StyledTitle>Outcomes</StyledTitle>
        <Text variant="body14" color={RUNE_GRAY_700} sx={STYLES.trends}>
          {SECTION_TITLE.trends}
        </Text>
        <InformationalIconTooltip
          placement="bottom-start"
          title={<Box>{TRENDS_TOOLTIP_TEXT}</Box>}
          data-cy="tooltip"
        />
      </Stack>
      <Subheading
        mainText={`${SECTION_TITLE.watchOrPhone}/${SECTION_TITLE.averageDailyValues}`}
        mainTextSeparator={'/'}
      />
    </Stack>
    <MotorMetrics />
    <MobilityMetrics />
    <SleepMetrics />
    <Subheading mainText="Patient Reported" />
    <OverviewPatientSymptoms />
    <OverviewPatientNotes />
  </Stack>
)

export default OverviewMainContent
