import { average } from 'utilities'
import { AFTER, BEFORE } from 'utilities/sort'
import { roundDownIntervalTime, rangeTimeIntervals } from 'utilities/time'
import { duration } from '../consts'
import moment from 'moment'
import { DATETIME_FORMAT_LONG } from 'ui/consts'

export const DEVICE_KINDS_STRIVESTUDY = ['strivestudy']
export const DEVICE_KINDS_APPLE_WATCH = ['applewatch', 'Apple Watch']

/**
 * Determine whether the arrays representing device states have any changes within them or not. If device states
 * appear to have a starting and ending value that are identical and span the entirety of the date range, it is
 * safe to conclude there was no meaningful state changes in that date range.
 * NOTE: This function assumes timestamps come back in `datetime` format. More robust check may include
 * processing timestamps into expected format before checking.
 * @param {number[]} data array of 'state' data points
 * @param {string[]} timeseries array of timestamps associated with data points
 * @param {bool} timezoneNameEnabled whether timezoneNameEnabled flag is on for user
 * @returns {boolean} true if data has no state changes
 */
export const hasNoStateChanges = (
  data = [],
  timeseries = [],
  timezoneNameEnabled = false,
) => {
  const [firstData, secondData] = data
  let [firstTimestamp, secondTimestamp] = timeseries
  // If timezonename is enabled, the timestamps will be in ISO format,
  // e.g. 2020-01-01T00:00:00.000-08:00. DATETIME_FORMAT_LONG shortens
  // the string to just the date and time with milliseconds, e.g. 2020-01-01 00:00:00.000,
  // for the regex matches below.
  if (timezoneNameEnabled) {
    firstTimestamp = moment(firstTimestamp).format(DATETIME_FORMAT_LONG)
    secondTimestamp = moment(secondTimestamp).format(DATETIME_FORMAT_LONG)
  }
  const ONLY_START_AND_END_DATAPOINTS_LENGTH = 2

  return (
    data.length === ONLY_START_AND_END_DATAPOINTS_LENGTH &&
    timeseries.length === ONLY_START_AND_END_DATAPOINTS_LENGTH &&
    firstData === secondData &&
    !!firstTimestamp.match(/^.*00:00:00\.000$/) &&
    !!secondTimestamp.match(/^.*23:59:59\.999$/)
  )
}

/**
 * Transpose two same-sized arrays of data into a singular array of coordinates
 * @param {number[]} data values corresponding to points in time
 * @param {datetime[]} times timestamps corresponding to data values
 * @return {array} array of {time, value} coordinates
 */
export const transposeCoordinates = (data = [], times = []) =>
  times.map((time, i) => ({
    time,
    value: data[i],
  }))

/**
 * For each data point consisting of time and value keys, round the date-timestamp
 * down to the nearest interval, in minutes. The date portion of the string is omitted.
 *
 * Written for use in standardizing data points to a specific interval of time.
 *
 * @param {object[]} data Array of data points, each representing a time and value at that time
 * @param {number} interval Number of minutes to round time down to
 * @returns {object[]} Same length array as input data array with rounded down time values and same data values
 */
export const roundDownTimestampCoordinates = (
  data = [],
  interval = duration.INTERVAL,
) =>
  data.map(({ time, value }) => ({
    time: roundDownIntervalTime(time, interval),
    value,
  }))

/**
 * Calculate data value averages from common time coordinates.
 *
 * Written for use in averaging values for a given time interval across multiple days.
 *
 * @param {object[]} coordinates - array of {time, value} objects
 * @return {object[]} array of averages by time
 */
export const calculateAverages = (coordinates) => {
  const groupedCoordinates = coordinates.reduce(
    (coordinateGroups, { time, value }) => {
      ;(coordinateGroups[time] = coordinateGroups[time] || []).push(value)
      return coordinateGroups
    },
    {},
  )

  const averages = Object.keys(groupedCoordinates)
    .sort()
    .reduce((averagedCoordinates, timestamp) => {
      averagedCoordinates.push({
        time: timestamp,
        value: average(groupedCoordinates[timestamp]),
      })
      return averagedCoordinates
    }, [])

  return averages
}

/**
 * Pad averages with time slots of zero intensity in the scenario that the averages
 * returned does not span a full day of data
 *
 * Example: padAverages([{ time: '00:10:00', value: 200 }]) becomes
 *          [
 *            { time: '00:00:00', value: 0 },
 *            { time: '00:10:00', value: 200 },
 *            { time: '00:20:00', value: 0 },
 *            ...
 *            { time: '23:50:00', value: 0 },
 *          ]
 *
 * @param {object[]} averages - array of averages with time and data values
 * @param {number} duration - the length of duration in minutes
 * @param {number} interval - minutes interval
 * @param {string} format - time string format
 * @returns {object[]} padded averages for a complete day range
 */
export const padAverages = (averages = [], duration, interval, format) => {
  const averagesDict = averages.reduce((acc, { time, value }) => {
    acc[time] = value
    return acc
  }, {})

  return rangeTimeIntervals(duration, interval, format).map((time) => ({
    time,
    value: averagesDict[time] || null,
  }))
}

/**
 * Get the ID of the first device in the devices array to match one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} ID of first matching device if found, undefined if none found
 */
export const getDeviceId = (devices, kinds) =>
  (
    (devices || []).find(
      ({ id, kind, disabled }) =>
        kinds.includes(kind) && typeof id !== 'undefined' && !disabled,
    ) || {}
  ).id

/**
 * Get the ID of the most recently created device in the devices array that matches one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} ID of first matching device if found, undefined if none found
 */
export const getLatestDeviceId = (devices, kinds) => {
  const defaultDate = 0
  const sorted = [...(devices || [])].sort((a, b) =>
    (b.createdAt || defaultDate) < (a.createdAt || defaultDate)
      ? AFTER
      : BEFORE,
  )
  return getDeviceId(sorted, kinds)
}

/**
 * Get the short ID of the first device in the devices array to match one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} short ID of first matching device if found, undefined if none found
 */
const getDeviceShortId = (devices, kinds) =>
  (
    (devices || []).find(
      ({ id, kind, disabled }) =>
        kinds.includes(kind) && typeof id !== 'undefined' && !disabled,
    ) || {}
  ).deviceShortId

/**
 * Get the short ID of the most recently created device in the devices array that matches one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} short ID of first matching device if found, undefined if none found
 */
const getLatestDeviceShortId = (devices, kinds) => {
  const defaultDate = 0
  const sorted = [...(devices || [])].sort((a, b) =>
    (b.createdAt || defaultDate) < (a.createdAt || defaultDate)
      ? AFTER
      : BEFORE,
  )
  return getDeviceShortId(sorted, kinds)
}

/**
 * Get the short ID of the most recently created Apple Watch.
 * @param {object[]} devices array of patient device objects
 * @returns {string} short ID of first matching Apple Watch if found, undefined if none found
 * **/
export const getLatestAppleWatchShortId = (devices) =>
  getLatestDeviceShortId(devices, DEVICE_KINDS_APPLE_WATCH)

/**
 * Get the short ID of the most recently created "strivestudy" device (Runespeak for iPhone).
 * @param {object[]} devices array of patient device objects
 * @returns {string} short ID of first matching iPhone if found, undefined if none found
 * **/
export const getLatestStriveStudyShortId = (devices) =>
  getLatestDeviceShortId(devices, DEVICE_KINDS_STRIVESTUDY)
