import { colors } from 'theme/colors'
import { Link } from 'ui/baseComponents/Link'
import { Text } from 'ui/baseComponents/Text'
import {
  NO_DATA_EMPTY_STATE,
  VIDEO_LINKS,
  WATCH_VIDEO,
} from 'ui/clinicianScreens/Patient/Report/consts'

export const SYMPTOM_LOG_SUBTITLE = {
  clinician: 'Data recorded from symptom logs and daily check-ins',
  patient: 'Data recorded from symptom logs, daily check-ins and notes',
}

export const SECTION_SYMPTOM_LOG_EMPTY_STATE = (
  <Text variant="body16" component="p" data-cy="symptom-log-section-no-data">
    {NO_DATA_EMPTY_STATE}
  </Text>
)

export const SYMPTOM_LOG_HELPER_TEXT = {
  emptyState:
    'Use the StrivePD app to log your symptoms and identify patterns in your behaviors. For further assistance, watch our videos on',
  learnMore:
    "Monitoring your symptoms is essential for effectively managing Parkinson's disease. To learn more about the importance of logging symptoms,",
}

export const SYMPTOM_LINK_TEXT = {
  loggingItems: 'how to log',
  importanceOfLoggingSymptoms: 'importance of logging symptoms',
}

export const HELPER_SYMPTOM_LOG = (
  <Text variant="body14" component="p" data-cy="symptom-log-helper">
    {SYMPTOM_LOG_HELPER_TEXT.learnMore}{' '}
    <Link
      href={VIDEO_LINKS.importanceOfLoggingSymptoms}
      external
      underline
      light
    >
      {WATCH_VIDEO}
    </Link>
    .
  </Text>
)

export const HELPER_SYMPTOM_LOG_EMPTY_STATE = (
  <Text variant="body14" component="p" data-cy="symptom-log-helper-no-data">
    {SYMPTOM_LOG_HELPER_TEXT.emptyState}{' '}
    <Link href={VIDEO_LINKS.loggingItems} external underline light>
      {SYMPTOM_LINK_TEXT.loggingItems}
    </Link>{' '}
    or on the{' '}
    <Link
      href={VIDEO_LINKS.importanceOfLoggingSymptoms}
      external
      underline
      light
    >
      {SYMPTOM_LINK_TEXT.importanceOfLoggingSymptoms}
    </Link>
    .
  </Text>
)

export const SYMPTOM_SUMMARY_EMPTY_STATE_TEXT =
  'No data available for comparison; log at least 7 days within a 4 week period.'

export const SYMPTOM_SUMMARY_EMPTY_STATE = (
  <Text variant="body14" data-cy="symptom-intensity-summary-no-data">
    {SYMPTOM_SUMMARY_EMPTY_STATE_TEXT}
  </Text>
)

export const SYMPTOM_INTENSITIES = {
  severe: 'severe',
  moderate: 'moderate',
  mild: 'mild',
  not_present: 'not_present',
  // TODO: remove the following after the Aug 19, 2024 report release:
  extremely_severe: 'extremely_severe',
  moderately_severe: 'moderately_severe',
  very_mild: 'very_mild',
}

export const SYMPTOM_INTENSITY_LABELS = {
  severe: 'Severe',
  moderate: 'Moderate',
  mild: 'Mild',
  not_present: 'Not Present',
  // TODO: remove the following after the Aug 19, 2024 report release:
  extremely_severe: 'Extremely Severe',
  moderately_severe: 'Moderately Severe',
  very_mild: 'Very Mild',
}

export const SYMPTOM_INTENSITY_CHIP_COLORS = new Map([
  [
    SYMPTOM_INTENSITIES.severe,
    { primary: colors.RED[600], secondary: colors.RED[100] },
  ],
  [
    SYMPTOM_INTENSITIES.moderate,
    { primary: colors.YELLOW[900], secondary: colors.YELLOW[200] },
  ],
  [
    SYMPTOM_INTENSITIES.mild,
    { primary: colors.GREEN[600], secondary: colors.GREEN[100] },
  ],
  [
    SYMPTOM_INTENSITIES.not_present,
    { primary: colors.BONDI[600], secondary: colors.BONDI[100] },
  ],
  // TODO: remove the following after the Aug 19, 2024 report release:
  [
    SYMPTOM_INTENSITIES.extremely_severe,
    { primary: colors.RED[600], secondary: colors.RED[100] },
  ],
  [
    SYMPTOM_INTENSITIES.moderately_severe,
    { primary: colors.YELLOW[900], secondary: colors.YELLOW[200] },
  ],
  [
    SYMPTOM_INTENSITIES.very_mild,
    { primary: colors.GREEN[600], secondary: colors.GREEN[100] },
  ],
])

export const SYMPTOM_INTENSITY_DOT_COLORS = new Map([
  [SYMPTOM_INTENSITIES.severe, colors.RED[500]],
  [SYMPTOM_INTENSITIES.moderate, colors.YELLOW[500]],
  [SYMPTOM_INTENSITIES.mild, colors.GREEN[500]],
  [SYMPTOM_INTENSITIES.not_present, colors.BONDI[300]],
])
export const RED_FLAG_CATEGORIES = {
  urgent: 'urgent',
  important: 'important',
  importantPT: 'importantPT',
  clinicallyActionable: 'clinicallyActionable',
}

const DEFAULT_RED_FLAG_CATEGORIES = [
  RED_FLAG_CATEGORIES.urgent,
  RED_FLAG_CATEGORIES.important,
  RED_FLAG_CATEGORIES.importantPT,
  RED_FLAG_CATEGORIES.clinicallyActionable,
]

const CLINICIAN_RED_FLAG_CATEGORIES = [
  RED_FLAG_CATEGORIES.urgent,
  RED_FLAG_CATEGORIES.important,
]

const SYMPTOM_INFO_LINKS = {
  falling: 'https://www.parkinson.org/library/fact-sheets/fall-prevention',
  vertigo:
    'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/vertigo',
  onOffTimes:
    'https://help.strive.group/hc/en-us/articles/16376339782285-On-Off-Times',
  hallucinations:
    'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/hallucinations-delusions',
  swallowing:
    'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/speech-swallowing',
  balance:
    'https://www.parkinson.org/understanding-parkinsons/movement-symptoms/postural-instability',
  freezing:
    'https://www.parkinson.org/living-with-parkinsons/management/activities-daily-living/freezing',
  troubleMoving:
    'https://www.parkinson.org/understanding-parkinsons/movement-symptoms/trouble-moving',
  rigidity:
    'https://www.parkinson.org/understanding-parkinsons/movement-symptoms/rigidity',
  tremor:
    'https://www.parkinson.org/understanding-parkinsons/movement-symptoms/tremor',
  dyskinesia:
    'https://www.parkinson.org/understanding-parkinsons/movement-symptoms/dyskinesia',
  dystonia:
    'https://www.parkinson.org/understanding-parkinsons/movement-symptoms/dystonia',
  pain: 'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/pain',
  constipation:
    'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/constipation',
  incontinence:
    'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/incontinence',
  fatigue:
    'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/fatigue',
  bradykinesia:
    'https://www.parkinson.org/understanding-parkinsons/movement-symptoms/bradykinesia',
  anxiety:
    'https://www.parkinson.org/understanding-parkinsons/non-movement-symptoms/anxiety',
}

export const RED_FLAG_CLASSIFICATIONS = new Map([
  [
    'falling',
    {
      category: RED_FLAG_CATEGORIES.urgent,
      url: SYMPTOM_INFO_LINKS.falling,
    },
  ],
  [
    'dizziness',
    {
      category: RED_FLAG_CATEGORIES.important,
      url: SYMPTOM_INFO_LINKS.vertigo,
    },
  ],
  [
    'lightheadedness',
    {
      category: RED_FLAG_CATEGORIES.important,
      url: SYMPTOM_INFO_LINKS.vertigo,
    },
  ],
  [
    'low-blood-pressure',
    {
      category: RED_FLAG_CATEGORIES.important,
      url: SYMPTOM_INFO_LINKS.vertigo,
    },
  ],
  [
    'off-period',
    {
      category: RED_FLAG_CATEGORIES.important,
      url: SYMPTOM_INFO_LINKS.onOffTimes,
    },
  ],
  [
    'akathisia',
    {
      category: RED_FLAG_CATEGORIES.important,
      url: SYMPTOM_INFO_LINKS.onOffTimes,
    },
  ],
  [
    'hallucinations',
    {
      category: RED_FLAG_CATEGORIES.important,
      url: SYMPTOM_INFO_LINKS.hallucinations,
    },
  ],
  [
    'swallowing-issues',
    {
      category: RED_FLAG_CATEGORIES.important,
      url: SYMPTOM_INFO_LINKS.swallowing,
    },
  ],
  [
    'balance-issues',
    {
      category: RED_FLAG_CATEGORIES.importantPT,
      url: SYMPTOM_INFO_LINKS.balance,
    },
  ],
  [
    'freezing',
    {
      category: RED_FLAG_CATEGORIES.importantPT,
      url: SYMPTOM_INFO_LINKS.freezing,
    },
  ],
  [
    'gait-issues',
    {
      category: RED_FLAG_CATEGORIES.importantPT,
      url: SYMPTOM_INFO_LINKS.troubleMoving,
    },
  ],
  [
    'rigidity',
    {
      category: RED_FLAG_CATEGORIES.importantPT,
      url: SYMPTOM_INFO_LINKS.rigidity,
    },
  ],
  [
    'tremor',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: SYMPTOM_INFO_LINKS.tremor,
    },
  ],
  [
    'on-with-troublesome-dyskinesia-period',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: SYMPTOM_INFO_LINKS.dyskinesia,
    },
  ],
  [
    'dyskinesia',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: SYMPTOM_INFO_LINKS.dyskinesia,
    },
  ],
  [
    'dystonia',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: SYMPTOM_INFO_LINKS.dystonia,
    },
  ],
  [
    'leg-cramps',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: '',
    },
  ],
  [
    'pain',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: SYMPTOM_INFO_LINKS.pain,
    },
  ],
  [
    'constipation',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: SYMPTOM_INFO_LINKS.constipation,
    },
  ],
  [
    'incontinence',
    {
      category: RED_FLAG_CATEGORIES.clinicallyActionable,
      url: SYMPTOM_INFO_LINKS.incontinence,
    },
  ],
  [
    'fatigue',
    {
      category: RED_FLAG_CATEGORIES.common,
      url: SYMPTOM_INFO_LINKS.fatigue,
    },
  ],
  [
    'slowness',
    {
      category: RED_FLAG_CATEGORIES.common,
      url: SYMPTOM_INFO_LINKS.bradykinesia,
    },
  ],
  [
    'anxiety',
    {
      category: RED_FLAG_CATEGORIES.common,
      url: SYMPTOM_INFO_LINKS.anxiety,
    },
  ],
])

export const DEFAULT_RED_FLAGS = [...RED_FLAG_CLASSIFICATIONS.keys()].filter(
  (key) =>
    DEFAULT_RED_FLAG_CATEGORIES.includes(
      RED_FLAG_CLASSIFICATIONS.get(key).category,
    ),
)

export const CLINICIAN_REPORT_RED_FLAGS = [
  ...RED_FLAG_CLASSIFICATIONS.keys(),
].filter((key) =>
  CLINICIAN_RED_FLAG_CATEGORIES.includes(
    RED_FLAG_CLASSIFICATIONS.get(key).category,
  ),
)

export const DYSKINESIA_SYMPTOMS = [
  'off-period',
  'on-without-dyskinesia-period',
  'on-with-troublesome-dyskinesia-period',
  'on-with-nontroublesome-dyskinesia-period',
  'dyskinesia',
]
