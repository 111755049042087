export const SECTION_TITLE = {
  sleep: 'Sleep data',
  patientReported: 'Patient-reported data',
  watch: 'Data recorded by Apple watch',
  watchOrPhone: 'Data recorded by Apple watch or phone',
  trends:
    'Arrows indicate a slight deviation from what is typically recorded by this patient.',
  averageDailyValues: 'Average Daily Values',
}

export const duration = Object.freeze({
  INTERVAL: 10,
  HALF_HOUR: 30,
})

export const PLACEHOLDER = '—'

export const TRENDS_TOOLTIP_TEXT = `We compare the averages you're viewing to the previous 90 days of data for this patient. Values that fall within the upper or lower quartiles of that historical distribution may indicate possible state changes in this patient's symptoms.`
