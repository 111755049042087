import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { patientName } from 'utilities/string'
import useBoundStore from 'domains/zustand/store'
import { PATIENT_SEARCH } from 'domains/carrotGraph/queries/patientSearch'

const usePatientSearch = ({ skip = false } = {}) => {
  const [patients, setPatients] = useState([])
  const [debouncedSearchInput, setDebouncedSearchInput] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })
  const searchPatientsInputFields = useBoundStore(
    (state) => state.searchPatientsInputFields,
  )
  const setSearchPatientsExecuted = useBoundStore(
    (state) => state.setSearchPatientsExecuted,
  )
  const setMoreSearchResultsAvailable = useBoundStore(
    (state) => state.setMoreSearchResultsAvailable,
  )

  const MINIMUM_SEARCH_LENGTH = 3

  const [searchPatients, { data, error, loading }] = useLazyQuery(
    PATIENT_SEARCH,
    {
      fetchPolicy: 'no-cache',
    },
  )

  useEffect(() => {
    const handleSearch = debounce((input) => {
      setDebouncedSearchInput(input)
    }, 300)

    handleSearch(searchPatientsInputFields)
    return () => {
      handleSearch.cancel()
    }
  }, [searchPatientsInputFields])

  useEffect(() => {
    if (
      (debouncedSearchInput.firstName?.length >= MINIMUM_SEARCH_LENGTH ||
        debouncedSearchInput.lastName?.length >= MINIMUM_SEARCH_LENGTH ||
        debouncedSearchInput.email?.length >= MINIMUM_SEARCH_LENGTH) &&
      !skip
    ) {
      setSearchPatientsExecuted(true)
      searchPatients({
        variables: {
          firstName: debouncedSearchInput.firstName,
          lastName: debouncedSearchInput.lastName,
          email: debouncedSearchInput.email,
        },
      })
    } else {
      setPatients([])
      setSearchPatientsExecuted(false)
      setMoreSearchResultsAvailable(false)
    }
  }, [debouncedSearchInput]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPatients(data?.patientSearch?.patients || [])
    setMoreSearchResultsAvailable(!!data?.patientSearch?.moreResults)
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const precomputedPatients = useMemo(
    () =>
      patients.map((patient) => {
        const preComputedName = patientName(patient).withLastFirst()
        return { ...patient, preComputedName }
      }),
    [patients],
  )

  useEffect(() => {
    useBoundStore.setState(() => ({
      searchPatientsLoading: loading,
      searchPatientsError: error,
      searchPatientsResults: precomputedPatients,
    }))
  }, [loading, error, precomputedPatients])
}

export default usePatientSearch
