import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { GridContainer, SubGrid } from 'ui/components/Layout'
import {
  MedicationAdherence,
  MedicationSchedule,
  NotesLog,
  TopPatientReportedSymptoms,
} from 'ui/clinicianScreens/Patient/Summary/components/Cards'
import { useFlags } from 'domains/launchdarkly/hooks'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    boxShadow: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '5%',
      bottom: '0',
      height: '1px',
      width: '90%',
      borderBottom: '1px solid #cccbc9',
    },
  },
  tableCell: {
    borderBottom: '0',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      borderBottom: '0',
    },
  },
  tableCellBold: {
    borderBottom: '0',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  tableCellDetails: {
    borderBottom: '0',
    textTransform: 'capitalize',
  },
  subTitle: {
    fontSize: '1.15rem',
  },
  emptyItem: {
    backgroundColor: theme.palette.action.hover,
    width: '100%',
  },
}))

const FULL_WIDTH = 12

const SummaryModeCards = () => {
  const classes = useStyles()
  const { overviewTabVisible } = useFlags()

  return (
    <GridContainer hasSubgrids>
      {!overviewTabVisible && (
        <SubGrid isFirst>
          <Grid item xs={FULL_WIDTH} lg={FULL_WIDTH}>
            <TopPatientReportedSymptoms classes={classes} />
          </Grid>
          <Grid item xs={FULL_WIDTH}>
            <NotesLog classes={classes} />
          </Grid>
        </SubGrid>
      )}
      {!overviewTabVisible && (
        <SubGrid>
          <Grid item xs={FULL_WIDTH}>
            <MedicationAdherence classes={classes} />
          </Grid>
          <Grid item xs={FULL_WIDTH}>
            <MedicationSchedule classes={classes} />
          </Grid>
        </SubGrid>
      )}
    </GridContainer>
  )
}

SummaryModeCards.propTypes = {}

SummaryModeCards.defaultProps = {}

export default SummaryModeCards
