import {
  DEVICE_TYPE_APPLE_WATCH,
  DEVICE_TYPE_EVENTS,
  DEVICE_TYPE_STRIVE_STUDY,
} from 'ui/screens/EditPatient/Devices/consts'

/**
 * Generate Stream API requests for data availability of this device's
 * supported streams.
 */
export const getAvailabilityRequests = (device, commonParams) => {
  switch (device.kind) {
    case DEVICE_TYPE_APPLE_WATCH.id:
    case DEVICE_TYPE_APPLE_WATCH.displayName: {
      return [
        getHeartRateAvailabilityRequest(device, commonParams),
        getDyskinesiaAvailabilityRequest(device, commonParams),
        getMotionAvailabilityRequest(device, commonParams),
        getTremorAvailabilityRequest(device, commonParams),
      ]
    }

    case DEVICE_TYPE_EVENTS.id:
    case DEVICE_TYPE_EVENTS.displayName: {
      return [
        getEventsAvailabilityRequest(commonParams),
        getSpansAvailabilityRequest(commonParams),
      ]
    }

    case DEVICE_TYPE_STRIVE_STUDY.id:
    case DEVICE_TYPE_STRIVE_STUDY.displayName: {
      return []
    }

    default: {
      /*
       * @todo: log this into Rollbar
       */
      console.warn(`Unsupported device type: '${device.kind}'`)
      return []
    }
  }
}

/**
 * Put together a Stream API request for availability of event data
 *
 * @param {object} commonParams Parameters common and identical to all stream requests
 */
export const getEventsAvailabilityRequest = (commonParams) => ({
  endpoint: 'event.json',
  params: {
    event: 'patient',
    ...commonParams,
  },
  success: ({ result }) => {
    const eventData = {
      type: 'events',
      data: result,
    }

    return eventData
  },
})

/**
 * Put together a Stream API request for availability of span data
 *
 * @param {object} commonParams Parameters common and identical to all stream requests
 */
export const getSpansAvailabilityRequest = (commonParams) => ({
  endpoint: 'span.json',
  params: {
    event: 'patient',
    ...commonParams,
  },
  success: ({ result }) => {
    const spanData = {
      type: 'spans',
      data: result,
    }

    return spanData
  },
})

/**
 * Put together a Stream API request for availability of dyskinesia
 * symptom detection data.
 */
export const getDyskinesiaAvailabilityRequest = (
  device,
  commonParams,
  fromClinician = false,
) => ({
  url: 'probability_symptom.json',
  endpoint: 'probability_symptom.json',
  alias: device.alias,
  params: {
    device_id: device.id,
    expression: 'availability(probability)',
    symptom: 'dyskinesia',
    ...commonParams,
  },
  success: ({ result }) => {
    const dataParts = result['availability(probability)']
    const timeParts = result['time']

    if (fromClinician) {
      return { label: `${device.alias} (Dyskinesia)`, data: result }
    }

    const { formattedDataParts, formattedTimeParts } =
      commonParams.partition_size
        ? { formattedDataParts: dataParts, formattedTimeParts: timeParts }
        : partitionMonths(dataParts, timeParts)

    return formattedTimeParts.map((timestamps, i) => ({
      label: `${device.alias} (Dyskinesia)`,
      x: timestamps,
      y: formattedDataParts[i],
    }))
  },
})

/**
 * Put together a Stream API request for availability of tremor symptom
 * detection data.
 */
export const getTremorAvailabilityRequest = (
  device,
  commonParams,
  fromClinician = false,
) => ({
  url: 'probability_symptom.json',
  endpoint: 'probability_symptom.json',
  params: {
    device_id: device.id,
    expression: 'availability(probability)',
    symptom: 'tremor',
    ...commonParams,
  },
  success: ({ result }) => {
    const dataParts = result['availability(probability)']
    const timeParts = result['time']

    if (fromClinician) {
      return result
    }

    const { formattedDataParts, formattedTimeParts } =
      commonParams.partition_size
        ? { formattedDataParts: dataParts, formattedTimeParts: timeParts }
        : partitionMonths(dataParts, timeParts)

    return formattedTimeParts.map((timestamps, i) => ({
      label: `${device.alias} (Tremor)`,
      x: timestamps,
      y: formattedDataParts[i],
    }))
  },
})

/**
 * Put together a Stream API request for availability of heart rate data
 */
export const getHeartRateAvailabilityRequest = (device, commonParams) => ({
  endpoint: 'heartrate.json',
  params: {
    device_id: device.id,
    expression: 'availability(bpm)',
    ...commonParams,
  },
  success: ({ result }) => {
    const dataParts = result['availability(bpm)']
    const timeParts = result['time']

    const { formattedDataParts, formattedTimeParts } =
      commonParams.partition_size
        ? { formattedDataParts: dataParts, formattedTimeParts: timeParts }
        : partitionMonths(dataParts, timeParts)

    return formattedTimeParts.map((timestamps, i) => ({
      label: `${device.alias} (Heart Rate)`,
      x: timestamps,
      y: formattedDataParts[i],
    }))
  },
})

/**
 * Put together a Stream API request for a device's motion data
 * availability.
 */
export const getMotionAvailabilityRequest = (
  device,
  commonParams,
  algorithm = null,
) => ({
  endpoint: 'accel.json',
  params: {
    device_id: device.id,
    expression: 'availability(accel)',
    algorithm: algorithm,
    ...commonParams,
  },
  success: ({ result }) => {
    const dataParts = result['availability(accel)']
    const timeParts = result.time

    const { formattedDataParts, formattedTimeParts } =
      commonParams.partition_size
        ? { formattedDataParts: dataParts, formattedTimeParts: timeParts }
        : partitionMonths(dataParts, timeParts)

    return formattedTimeParts.map((timestamps, i) => ({
      label: `${device.alias} (Motion)`,
      x: timestamps,
      y: formattedDataParts[i],
    }))
  },
})

/**
 * Helper function that splits monthly data into partitions of their individual months.
 *
 * @param {array} dataParts data array to be formatted
 * @param {array} timeParts time array to be formatted
 */
export const partitionMonths = (dataParts, timeParts) => {
  let data = dataParts
  let slicedTimeParts = timeParts.map((timestamp) => timestamp.slice(0, 10))

  // responses at this zoomed-out resolution return an extra day.
  // In timezones that are back from GMT (IE USA) that day is on the front,
  // but the availability data is shifted. This corrects that.
  if (slicedTimeParts[0].slice(0, 7) !== slicedTimeParts[1].slice(0, 7)) {
    slicedTimeParts.shift()
    data.pop()
  }

  // In timezones that are forward from GMT (IE Asia) that day is on the back,
  // but the availability data is shifted the same way. This conditional corrects that case.
  if (
    slicedTimeParts[slicedTimeParts.length - 1].slice(0, 7) !==
    slicedTimeParts[slicedTimeParts.length - 2].slice(0, 7)
  ) {
    slicedTimeParts.pop()
    data.pop()
  }

  // if there is only one month of data, simply return it in one partition
  if (slicedTimeParts < 32) {
    return {
      formattedDataParts: [data],
      formattedTimeParts: [slicedTimeParts],
    }
  }

  let monthSwitchPositions = [0]
  let currentMonth = slicedTimeParts[0]
  for (let i = 1; i < slicedTimeParts.length; i++) {
    // 7 is the number of characters for the year and month in a standard date
    // (YYYY-MM). Here we're detecting where the month switches.
    if (slicedTimeParts[i].slice(0, 7) !== currentMonth.slice(0, 7)) {
      monthSwitchPositions.push(i)
      currentMonth = slicedTimeParts[i]
    }
  }

  let newDataParts = []
  let newTimeParts = []

  for (let i = 0; i < monthSwitchPositions.length; i++) {
    newDataParts.push(
      data.slice(monthSwitchPositions[i], monthSwitchPositions[i + 1]),
    )
    newTimeParts.push(
      slicedTimeParts.slice(
        monthSwitchPositions[i],
        monthSwitchPositions[i + 1],
      ),
    )
  }

  return {
    formattedDataParts: newDataParts,
    formattedTimeParts: newTimeParts,
  }
}
