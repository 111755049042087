import { memo } from 'react'
import { colors } from 'theme/colors'
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  Legend,
  LabelList,
  Label,
} from 'recharts'
import { Box } from '@mui/material'
import { DEBOUNCE_DELAY_DEFAULT } from './consts'

const BarChart = ({
  title,
  data,
  legendItem,
  legend,
  height = 200,
  showBarLabels = false,
  barSize = 10,
  hideGridLines = false,
  hideXAxis = false,
  hideYAxis = false,
  xAxisLabel,
  xAxisInterval = 11,
  yAxisUnit,
  yAxisMax,
  tooltip,
  referenceLineValue,
  referenceLineLabel,
  secondaryBarLabels,
  isAnimationActive,
  margin = { top: 0, right: 16, left: 10, bottom: 5 },
  barRadius = [0, 0, 0, 0],
  chartId = 'bar-chart',
}) => {
  const STYLES = {
    chartContainer: {
      width: '100%',
      height,
      '& .recharts-legend-item-text': {
        color: `${colors.GREY[700]} !important`, // overriding js
        fontSize: '12px',
        verticalAlign: 'middle',
      },
      '& .recharts-legend-wrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: title ? 'space-between' : 'end',
        width: '100%',
        ...(title && {
          '&::before': {
            content: `"Time with ${title}"`,
            color: colors.GREY[700],
            marginLeft: '3.5rem',
          },
        }),
      },
      '@media print': {
        '& .recharts-legend-wrapper::before': {
          marginLeft: '4.75cm',
        },
      },
    },
  }

  return (
    <Box sx={STYLES.chartContainer} data-chart-id={chartId}>
      <ResponsiveContainer
        debounce={DEBOUNCE_DELAY_DEFAULT}
        width="100%"
        height={height}
      >
        <RechartsBarChart
          width={500}
          height={height}
          data={data}
          margin={margin}
        >
          <CartesianGrid
            strokeDasharray="0"
            stroke={colors.TREMOR[100]}
            horizontal={!hideGridLines}
            vertical={!hideGridLines}
          />
          <XAxis
            dataKey="name"
            interval={xAxisInterval}
            tickMargin={8}
            fontSize={10}
            axisLine={{ stroke: colors.GREY[400] }}
            tickLine={{ stroke: colors.TREMOR[300] }}
            hide={hideXAxis}
          >
            <Label
              value={xAxisLabel}
              offset={showBarLabels ? 25 : 0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            unit={yAxisUnit}
            tickMargin={8}
            fontSize={10}
            axisLine={{ stroke: colors.TREMOR[100] }}
            tickLine={{ stroke: colors.TREMOR[100] }}
            domain={[0, yAxisMax]}
            hide={hideYAxis}
          />
          {tooltip && (
            <Tooltip cursor={{ fill: colors.PRIMARY[200] }} content={tooltip} />
          )}
          {legend && (
            <Legend
              verticalAlign="top"
              align="right"
              iconType="square"
              iconSize={10}
              wrapperStyle={{ top: -8, fontSize: 10 }}
            />
          )}
          {legendItem && (
            <Bar
              {...{
                key: legendItem.name,
                dataKey: legendItem.name,
                fill: legendItem.color,
                barSize,
                isAnimationActive,
                radius: barRadius,
              }}
            >
              {showBarLabels && (
                <>
                  <LabelList dataKey={legendItem.name} position="bottom" />
                  {secondaryBarLabels}
                </>
              )}
            </Bar>
          )}
          <ReferenceLine
            label={referenceLineLabel}
            y={referenceLineValue}
            stroke={colors.LIME[700]}
            strokeWidth={3}
          />
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default memo(BarChart)
