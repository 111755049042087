import { useCallback, useContext, useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'redux-first-history'
import { authenticate } from 'domains/auth/actions'
import SignInContext from 'ui/contexts/SignInContext'
import SignInForm from './SignInForm'
import ResetPassword from './ResetPassword/ResetPassword'
import CreateNewPasswordForm from './CreateNewPasswordForm'

export const SignIn = ({ authenticate, push }) => {
  const { email, forgotPassword, password, setError, setPassword } =
    useContext(SignInContext)

  const [newPassRequired, setNewPassRequired] = useState(false)
  const [user, setUser] = useState(null)

  /**
   * Authenticate credentials with Cognito user pool and establish a session.
   * @param e
   */
  const onSubmitSignInForm = useCallback(
    (e) => {
      e.preventDefault()

      if (!(email && password)) {
        return
      }

      authenticate(email.toLowerCase(), password)
        .then(() => push('/'))
        .catch((reason) => {
          if (reason.newPasswordRequired) {
            /* First login: requires password change */
            setUser(reason.user)
            setPassword('')
            setNewPassRequired(reason.scope)
            return
          }
          setError(reason)
        })
    },
    [authenticate, email, password, push, setError, setPassword],
  )

  if (forgotPassword) {
    return <ResetPassword />
  }

  if (newPassRequired) {
    return <CreateNewPasswordForm user={user} />
  }

  return <SignInForm onSubmit={onSubmitSignInForm} />
}

const mapDispatchToProps = {
  authenticate,
  push,
}
export default connect(null, mapDispatchToProps)(SignIn)
