import { create } from 'zustand'
import {
  dateJumpAlertSlice,
  eventsSlice,
  patientListSlice,
  reportsSlice,
  searchPatientsSlice,
} from './index'

const useBoundStore = create((...args) => ({
  ...dateJumpAlertSlice(...args),
  ...eventsSlice(...args),
  ...patientListSlice(...args),
  ...reportsSlice(...args),
  ...searchPatientsSlice(...args),
}))

export default useBoundStore
