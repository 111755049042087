import { Fragment, useContext, useState } from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import ChartLoading from 'ui/components/ChartLoading'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import ErrorFrame from 'ui/components/ErrorFrame'
import DragModeContext from 'ui/screens/Patient/Browse/DragModeContext'
import {
  SECOND_IN_MILLISECONDS,
  fmtMomentLocal,
  parseNaiveDatetime,
} from 'utilities/time'

import StreamActions from './StreamActions'
import Events from './Events/Events'

const useStyles = makeStyles((theme) => ({
  plot: {
    width: '100%',
  },
  root: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
    width: '100%',
  },
  title: {
    fontSize: '1.2rem',
    textAlign: 'center',
  },
}))

/**
 * Wrapper component for all stream visualizations.
 * @returns {React.Component} StreamVisualization component
 */
const StreamVisualization = ({
  children,
  height = 300,
  device,
  downloadOptions,
  error,
  showLoading,
  patient,
  title,
  tStart,
  tEnd,
  updateTimeRange,
  timezoneNameEnabled,
}) => {
  const { dragmode, setDragmode } = useContext(DragModeContext)
  const [yRange, setYRange] = useState()
  const [showEvents, setShowEvents] = useState(false)
  const [showSymptoms, setShowSymptoms] = useState(true)
  const [showMedication, setShowMedication] = useState(true)
  const [showFood, setShowFood] = useState(true)
  const [showTasks, setShowTasks] = useState(true)
  const [showActivity, setShowActivity] = useState(true)
  const [showSleep, setShowSleep] = useState(true)
  const classes = useStyles()

  /**
   * Click event (stub).
   * @param {object} event the click event
   * @returns {undefined}
   */
  const onClick = (event) => {}

  /**
   * Initialization event (stub)
   * @param {object} figure a figure
   * @returns {undefined}
   */
  const onInitialized = (figure) => {}

  /**
   * Handle zoom events
   * @param {object} event the zoom event
   * @returns {undefined}
   */
  const onRelayout = (event) => {
    const {
      dragmode,
      'xaxis.range[0]': dtStartNew,
      'xaxis.range[1]': dtEndNew,
      'yaxis.autorange': yAutorange,
      'yaxis.range[0]': yLowNew,
      'yaxis.range[1]': yHighNew,
    } = event

    if (dragmode) {
      // Dragging has begun/ended
      setDragmode(dragmode)
    }

    if (yAutorange) {
      // Resetting custom y range. Note that Plotly will also tries to zoom
      // out on time axis, but that is not the desired behavior for us.
      setYRange(null)
    }

    let tStartNew = null
    let tEndNew = null
    const SECOND_IN_MILLISECONDS_FLOAT = 1000.0

    if (dtStartNew && dtEndNew) {
      // Parse new datetime range
      const mtStartNew = parseNaiveDatetime(dtStartNew, timezoneNameEnabled)
      const mtEndNew = parseNaiveDatetime(dtEndNew, timezoneNameEnabled)

      if (!(mtStartNew.isValid() && mtEndNew.isValid())) {
        return
      }

      tStartNew = mtStartNew.valueOf() / SECOND_IN_MILLISECONDS_FLOAT
      tEndNew = mtEndNew.valueOf() / SECOND_IN_MILLISECONDS_FLOAT
    }

    if (tStartNew && tEndNew) {
      // Time zoom
      const tRangeDelta = [
        Math.round((tStartNew - tStart) * SECOND_IN_MILLISECONDS),
        Math.round((tEndNew - tEnd) * SECOND_IN_MILLISECONDS),
      ]
      if (tEndNew - tStartNew < 1.0) {
        // Don't allow sub-second zoom, too easy to land between points
        return
      }

      updateTimeRange(tStartNew, tEndNew)

      // Update y range, unless we suspect zoom button was clicked (zoom
      // in/out by perfectly symmetrical amount, unlikely for a human drag)
      //
      // This is a slight hack, since the built-in mode bar buttons do not
      // have the customizable behavior we want. Proper fix would be to
      // introduce our own buttons outside of Plotly component.
      if (yLowNew && yHighNew && tRangeDelta[0] !== -tRangeDelta[1]) {
        setYRange([yLowNew, yHighNew])
      }
    } else if (yLowNew && yHighNew) {
      // Y-zoom only
      setYRange([yLowNew, yHighNew])
    }
  }

  const defaultOptions = {
    className: classes.plot,
    config: {
      displaylogo: false,
      displayModeBar: true,
      scrollZoom: false,
      responsive: true,
      modeBarButtons: [
        ['pan2d', 'zoom2d', 'zoomIn2d', 'zoomOut2d', 'resetScale2d', 'toImage'],
      ],
    },
    layout: {
      height,
      hoverlabel: {
        font: {
          size: 12,
        },
        align: 'left',
      },
      hovermode: 'closest',
      margin: {
        l: 100,
        r: 100,
        t: 24,
        b: 16,
        autoexpand: false,
      },
      title: false,
      xaxis: {
        annotations: false,
        fixedrange: false,
        range: [
          fmtMomentLocal(
            'Y-MM-DD HH:mm:ss.SSS',
            tStart * SECOND_IN_MILLISECONDS,
            timezoneNameEnabled,
          ),
          fmtMomentLocal(
            'Y-MM-DD HH:mm:ss.SSS',
            tEnd * SECOND_IN_MILLISECONDS,
            timezoneNameEnabled,
          ),
        ],
        font: {
          color: '#333',
          family: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Work Sans',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif',
          ].join(', '),
          size: 14,
        },
        hovermode: 'closest',
      },
      yaxis: {
        autorange: !yRange,
        fixedrange: false,
        range: yRange,
      },
      dragmode,
    },
    onClick,
    onInitialized,
    onRelayout,
    useResizeHandler: true,
  }

  const toggleShowEvents = () => {
    // safely toggles state without risk of async failure
    setShowEvents((x) => !x)
  }
  const toggleShowSymptoms = () => {
    setShowSymptoms((x) => !x)
  }
  const toggleShowMedication = () => {
    setShowMedication((x) => !x)
  }
  const toggleShowFood = () => {
    setShowFood((x) => !x)
  }
  const toggleShowTasks = () => {
    setShowTasks((x) => !x)
  }
  const toggleShowActivity = () => {
    setShowActivity((x) => !x)
  }
  const toggleShowSleep = () => {
    setShowSleep((x) => !x)
  }

  return (
    <ErrorBoundary>
      <Grid container className={classes.root} direction="column" spacing={1}>
        <Typography variant="h6" className={classes.title}>
          {device.alias}: {title}
        </Typography>
        {showLoading ? (
          <Fragment>
            <ChartLoading height={height} />
            {/* a bit hacky, but this spacer accounts for the StreamActions bar*/}
            <Box height={68}></Box>
          </Fragment>
        ) : error ? (
          <ErrorFrame />
        ) : (
          <Fragment>
            {showEvents && (
              <Events
                patient={patient}
                tStart={tStart}
                tEnd={tEnd}
                showSymptoms={showSymptoms}
                showMedication={showMedication}
                showFood={showFood}
                showTasks={showTasks}
                showActivity={showActivity}
                showSleep={showSleep}
              />
            )}
            <Box height={height}>{children(defaultOptions)}</Box>
            <StreamActions
              downloadOptions={downloadOptions}
              device={device}
              patient={patient}
              showEvents={showEvents}
              toggleShowEvents={toggleShowEvents}
              toggleShowSymptoms={toggleShowSymptoms}
              toggleShowMedication={toggleShowMedication}
              toggleShowFood={toggleShowFood}
              toggleShowTasks={toggleShowTasks}
              toggleShowActivity={toggleShowActivity}
              toggleShowSleep={toggleShowSleep}
              showSymptoms={showSymptoms}
              showMedication={showMedication}
              showFood={showFood}
              showTasks={showTasks}
              showActivity={showActivity}
              showSleep={showSleep}
              updateTimeRange={updateTimeRange}
              tStart={tStart}
              tEnd={tEnd}
            />
          </Fragment>
        )}
      </Grid>
    </ErrorBoundary>
  )
}

export default StreamVisualization
