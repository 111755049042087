import moment from 'moment'
import qs from 'qs'

export const getEventName = (event) =>
  event?.customDetail?.displayName || event?.displayName

export const stringifyQueryParameters = (startTime, endTime) =>
  qs.stringify(
    {
      startTime,
      endTime,
    },
    {
      // Sorting query parameter keys in descending order
      // Docs: https://github.com/ljharb/qs
      sort: (a, b) => -a.localeCompare(b),
    },
  )

export const getFormattedDaysOfWeek = (schedule) => {
  const hasWeeklySchedule = schedule.length > 0
  if (schedule.length === 7) {
    return ['Daily']
  }
  return hasWeeklySchedule
    ? schedule.map((dayNumber) => moment().day(dayNumber).format('ddd'))
    : []
}

export const createUniqueId = () => Date.now().toString()

const getScheduledMedNames = (schedules) => {
  const scheduledAsNeededMedNames = new Set()
  const allActiveScheduledMedNames = new Set()

  // The nesting in graphql needs flattening and sorting into medication arrays
  schedules.forEach((item) => {
    const { scheduleType } = item
    if (item?.active) {
      const medicationName = getEventName(item)
      allActiveScheduledMedNames.add(medicationName)
      if (scheduleType === 'ON_DEMAND') {
        scheduledAsNeededMedNames.add(medicationName)
      }
    }
  })

  return {
    scheduledAsNeededMedNames,
    allActiveScheduledMedNames,
  }
}

export const getLoggedAsNeededAndActiveScheduledMeds = (
  schedules,
  eventList,
  prevEventList,
) => {
  const { scheduledAsNeededMedNames, allActiveScheduledMedNames } =
    getScheduledMedNames(schedules)

  let loggedAsNeededMeds
  let prevLoggedAsNeededMeds

  // A person can log medications that are not scheduled. These medications are also considered as needed.
  if (eventList) {
    loggedAsNeededMeds = eventList.filter((event) => {
      const medicationName = getEventName(event)

      return (
        scheduledAsNeededMedNames.has(medicationName) ||
        !allActiveScheduledMedNames.has(medicationName)
      )
    })
  }

  if (prevEventList) {
    prevLoggedAsNeededMeds = prevEventList.filter((event) => {
      const medicationName = getEventName(event)

      return (
        scheduledAsNeededMedNames.has(medicationName) ||
        !allActiveScheduledMedNames.has(medicationName)
      )
    })
  }

  return {
    loggedAsNeededMeds,
    prevLoggedAsNeededMeds,
    allActiveScheduledMedNames,
  }
}
