import { useContext, useState } from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { monthlyAvailabilityColorArray } from '../consts.js'
import Plot from 'ui/components/Plot'
import { DAY_IN_SECONDS, parseNaiveDatetime } from 'utilities/time'
import './DayBitmapPlot.scss'
import { TimeBasisContext } from 'ui/components/TimeBasis/TimeBasisContext'
import { TIME_BASIS_RELATIVE } from 'ui/components/TimeBasis/consts'
import { getDayName } from 'utilities/dates'

const useStyles = makeStyles((theme) => ({
  dateContainer: {
    // set minWidth so that all of these date containers are the same width
    minWidth: 100,
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
  },
  plot: {
    width: '100%',
  },
}))

export const TimeBasisDate = ({ date }) => {
  const classes = useStyles()
  const { timeBasis } = useContext(TimeBasisContext)
  const { type, eventTimestamp } = timeBasis
  const dateReadableName = date.isSame(moment(), 'd')
    ? 'Today'
    : date.format('MMM D')

  const isRelative = type === TIME_BASIS_RELATIVE && eventTimestamp
  if (isRelative) {
    const relativeDaysName = getDayName(date, eventTimestamp)
    return (
      <div className={classes.dateContainer}>
        <Typography variant="subtitle1">{relativeDaysName}</Typography>
        <Typography variant="body2">{dateReadableName}</Typography>
      </div>
    )
  }
  return (
    <div className={classes.dateContainer}>
      <Typography variant="subtitle1">{dateReadableName}</Typography>
      <Typography variant="body2">{date.format('dddd')}</Typography>
    </div>
  )
}

/**
 * Visualization of a patient's data availability bitmap for a single time period.
 *
 * @returns {JSX.Element} The plot for a day of availability data
 */
const DayBitmapPlot = ({ streams, onSelect }) => {
  const classes = useStyles()
  const [revision, setRevision] = useState(0)

  const [proto] = streams
  const keys = [...streams.keys()]
  const date = moment(proto.x[0])

  const NEAR_ZERO_PERCENTAGE = 0.0001
  const PLOT_MARGIN_L = 30
  const PLOT_MARGIN_R = 240
  const PLOT_MARGIN_T = 0
  const PLOT_MARGIN_B = 24
  const PLOT_MAX_HEIGHT = 120
  const STREAM_HEIGHT = 30
  let hasData = false

  const z = keys.map((s) => {
    const stream = streams[s].y
    // @note: it's O(n^3) which is shitty performance, eventually need to
    //        either do this in backend or find plotting method that doesn't
    //        involve iterating over every number of the array
    for (let t = 0; t < stream.length; t++) {
      if (!stream[t]) {
        stream[t] = 0
      } else {
        stream[t] = s + 1
        hasData = true
      }
    }
    // ^^^ DO NOT use map() for this step. map() creates a new array, this
    //     needs to be done in-place
    return stream
  })

  // assigns colors from the monthlyAvailabilityColorArray to heatmap percentages
  const generateColorScale = () => {
    const colorScaleArray = z.flatMap((zItem, i) => {
      const percentage = (1 / z.length) * (i + 1)
      const adjustedPercentage =
        percentage < 1 ? percentage + NEAR_ZERO_PERCENTAGE : percentage
      return [
        [adjustedPercentage, monthlyAvailabilityColorArray[i]],
        [adjustedPercentage, monthlyAvailabilityColorArray[i + 1]],
      ]
    })
    colorScaleArray.pop()
    return colorScaleArray
  }

  const colorScale = generateColorScale()
  const labels = keys.map((s) => streams[s].label)
  const tickvalues = [...Array(labels.length).keys()] // values to assign to labels, allows for duplicate device names
  const customdata = labels.map((label) => Array(z[0].length).fill(label))
  // because of tickvalues fix, we must format customdata to match the structure of z

  const data = {
    name: '',
    colorscale: hasData
      ? [
          [0, '#ffffff'],
          [NEAR_ZERO_PERCENTAGE, '#ffffff'],
          [NEAR_ZERO_PERCENTAGE, '#2A27D3'],
          ...colorScale,
        ]
      : [
          [0.0, 'rgb(255,255,255)'],
          [1.0, 'rgb(255,255,255)'],
        ],
    showscale: false,
    type: 'heatmap',
    x: proto.x,
    y: tickvalues,
    z: z,
    customdata: customdata,
    hovertemplate: `
      <b>%{customdata}<br><br>
      %{x}<br><br></b>
      Click and drag to zoom
    `,
  }

  const height = Math.max(PLOT_MAX_HEIGHT, streams.length * STREAM_HEIGHT)

  const layout = {
    font: { family: 'Work Sans' },
    height,
    margin: {
      l: PLOT_MARGIN_L,
      r: PLOT_MARGIN_R,
      t: PLOT_MARGIN_T,
      b: PLOT_MARGIN_B,
    },
    showlegend: false,
    visible: true,
    xaxis: {
      automargin: false,
      // fixedrange: true,
      range: [
        date.format('YYYY-MM-DD 00:00:00'),
        moment(date).add(1, 'day').format('YYYY-MM-DD 00:00:00'),
      ],
      zeroline: true,
      linecolor: '#eeeeee',
      showline: true,
      tickfont: {
        color: '#666',
      },
    },
    yaxis: {
      automargin: false,
      fixedrange: true,
      showgrid: false,
      side: 'right',
      zeroline: false,
      zerolinecolor: '#eeeeee',
      showline: false,
      tickcolor: '#ffffff',
      ticklen: 30,
      ticks: 'outside',
      type: 'category',
      visible: true,
      tickfont: {
        size: 11,
      },
      tickmode: 'array',
      ticktext: labels,
      tickvals: tickvalues,
    },
    hoverlabel: {
      bgcolor: '#111111',
      bordercolor: '#111111',
      font: {
        color: '#FDFCFA',
      },
    },
  }

  const onRelayout = (event) => {
    if (event['xaxis.range[0]']) {
      const { 'xaxis.range[0]': start, 'xaxis.range[1]': end } = event
      const startTime = parseNaiveDatetime(start)
      const endTime = parseNaiveDatetime(end)

      if (endTime.unix() - startTime.unix() === DAY_IN_SECONDS) {
        // Someone dragged the x-axis; we don't allow that
        setRevision(revision + 1)
      } else {
        onSelect(startTime, endTime)
      }

      return false
    }
    return true
  }

  return (
    <div
      data-cy="day-data-bitmap-plot"
      className="day-data-bitmap-plot"
      style={{ height: `${height}px` }}
    >
      <TimeBasisDate date={date} />
      <div className="bitmap-plot">
        <Plot
          className={classes.plot}
          config={{
            autosize: true,
            responsive: true,
            displayModeBar: false,
            doubleClick: false,
            scrollZoom: false,
          }}
          data={[data]}
          layout={layout}
          onRelayout={onRelayout}
          revision={revision}
          useResizeHandler={true}
        />
      </div>
    </div>
  )
}

export default DayBitmapPlot
