import moment from 'moment-timezone'
import {
  dateToTimestamp,
  DAY_IN_SECONDS,
  SECOND_IN_MILLISECONDS,
  floorTimestamp,
  convertMomentObjectToSpecifiedTimezoneWithFormatting,
} from 'utilities/time'
import {
  DATETIME_FORMAT,
  DATETIME_FORMAT_SHORT,
  DATE_FORMAT,
  TIME_FORMAT,
} from 'ui/consts'
import {
  INTENSITY_LEVEL,
  MEDICATION_OR_SUPPLEMENT_LABEL,
  ON_OFF_PERIODS,
  PLOT_CONFIG,
  SLEEP_STATE,
  SYMPTOM_OR_SIDE_EFFECT_LABEL,
} from '../consts'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'

/*  This file contains helpers that were in the original helpers.js file for Daily View and:
    1) do not neatly fall into the categories defined in the other files in the helpers directory.
    2) it is not clear what would be the best way to categorize them though several of them have to do with day/dates.
*/

/**
 * Determine whether category is medication or supplement
 * @param {string} category category name
 * @returns {boolean} Whether the category is medication or supplement
 */
const isMedicationOrSupplement = (category) => {
  const medicationOrSupplement = [
    'medication',
    'supplement',
    'medicationOrSupplement',
  ]
  return medicationOrSupplement.includes(category)
}

/**
 * Determine whether category is a symptom or side effect
 * @param {string} category category name
 * @returns {boolean} Whether the category is a symptom or side effect
 */
export const isSymptomOrSideEffect = (category) => {
  const symptomOrSideEffect = ['symptom', 'side-effect', 'symptomOrSideEffect']
  return symptomOrSideEffect.includes(category)
}

/**
 * Determine whether the event display name indicates an on/off period
 * @param {string} eventDisplayName event display name
 * @returns {boolean} Whether the event display name indicates an on/off period
 */
export const isOnOffPeriod = (eventDisplayName) =>
  Object.values(ON_OFF_PERIODS).includes(eventDisplayName)

/**
 * Format the intensity text for the plot hover status
 *
 * @param {object} intensityData Values used to fomat the intensity text
 * @param {mixed} intensityData.intensity Either an integer or string
 * @param {bool} intensityData.includeColon Whether to include a colon in the returned string
 * @returns {string} String specifying the intensity level (mild, typical, or severe) when the value of intensity is 1, 2 or 3. Empty string in other cases, including when intensity is 'ON Period' or 'OFF Period'.
 */
export const generateIntensityLevelText = ({
  intensity,
  includeColon = true,
}) => {
  switch (intensity) {
    case INTENSITY_LEVEL.severe:
      return includeColon ? ': Severe intensity' : 'Severe intensity'
    case INTENSITY_LEVEL.typical:
      return includeColon ? ': Typical intensity' : 'Typical intensity'
    case INTENSITY_LEVEL.mild:
      return includeColon ? ': Mild intensity' : 'Mild intensity'
    default:
      return ''
  }
}

/**
 * Take the individual events and format their data to span multiple days if needed
 *
 * @param {string} startDateString Formatted local start time of day (without h/m/s)
 * @param {string} endDateString Formatted local start time of next day (without h/m/s)
 * @param {object} eventsFormatted The object for holding the formatted event data
 * @param {object} eventPlotDataTemplate The formatting for the individual event data
 * @param {string} startDatetimeString The formatted start time for the event (includes h/m/s)
 * @param {string} endDatetimeString The formatted end time for the event (includes h/m/s)
 * @returns {object} The object keys correspond to dates. Each date has a value which is an array of objects that include intensity (number, string, or undefined) and plotData (object).
 */
export const groupEventDataByDay = (
  startDateString,
  endDateString,
  eventsFormatted,
  eventPlotDataTemplate,
  startDatetimeString,
  endDatetimeString,
) => {
  const eventPlotData = JSON.parse(JSON.stringify(eventPlotDataTemplate))
  // So as to not directly mutate the formatted events or the individual trace
  const individualTraceCopy = { ...eventPlotDataTemplate }
  const copiedEventsFormatted = { ...eventsFormatted }

  if (startDateString !== endDateString) {
    if (!eventsFormatted[endDateString]) {
      copiedEventsFormatted[endDateString] = []
    }
    const endOfDay = moment(startDateString)
      .endOf('day')
      .format(DATETIME_FORMAT_SHORT)

    // We need a deep copy so as to push two different items to different dates
    eventPlotData.plotData.x = [startDatetimeString, endOfDay]
    copiedEventsFormatted[startDateString].push(eventPlotData)
    individualTraceCopy.plotData.x = [endDateString, endDatetimeString]
    copiedEventsFormatted[endDateString].push(individualTraceCopy)
  } else {
    copiedEventsFormatted[startDateString].push(individualTraceCopy)
  }

  return copiedEventsFormatted
}

/**
 * Determine the Y Axis label for the given event
 * @param {object} event Event data
 * @returns {string} Y Axis label
 */
export const determineYAxisLabelForEvent = (event) => {
  const {
    onPeriod,
    offPeriod,
    onWithNonTroublesomeDyskinesia,
    onWithTroublesomeDyskinesia,
    onWithoutDyskinesia,
  } = ON_OFF_PERIODS
  if (
    isSymptomOrSideEffect(event.classification.category) &&
    !isOnOffPeriod(event.displayName)
  ) {
    return SYMPTOM_OR_SIDE_EFFECT_LABEL
  }
  if (isMedicationOrSupplement(event.classification.category)) {
    return MEDICATION_OR_SUPPLEMENT_LABEL
  }
  switch (event.displayName) {
    case onWithNonTroublesomeDyskinesia:
    case onWithTroublesomeDyskinesia:
    case onWithoutDyskinesia:
    case onPeriod:
    case offPeriod:
      return 'ON/OFF'
    default:
      return event.displayName
  }
}

/**
 * Determine the text to display for the given event
 * @param {object} event Event data
 * @param {string} name Event name
 * @param {string} intensity Event intensity
 * @param {string} startTimeString Formatted start time of event
 * @returns {array} Text summary of event
 */
export const determineTextForEvent = (
  event,
  name,
  intensity,
  startTimeString,
) => {
  let text

  if (event.classification.category === 'sleep') {
    const sleepStatus =
      intensity === SLEEP_STATE.inBed
        ? 'In Bed'
        : intensity === SLEEP_STATE.asleep
        ? 'Asleep'
        : 'Awake'
    text = `Sleep status: ${sleepStatus}`
  } else {
    text = name + generateIntensityLevelText({ intensity })
  }
  return { eventText: text, eventTime: startTimeString }
}

/**
 * Consolidate events into half hour windows
 * @param {array} events Array of category events (medication/supplement, symptom/side effect, other)
 * @returns {object} Events consolidated into half hour windows with the object keys corresponding to the start time of the window and the value being an array of events
 */
export const groupEventsIntoHalfHourWindows = (events) => {
  const eventsGroupedByHalfHourWindows = {}

  const sortedEvents = events.sort(
    (a, b) => a.duration.startTime - b.duration.startTime,
  )

  sortedEvents.forEach((event) => {
    const startTimeInSeconds = event.duration.startTime
    const startTimeWindow = floorTimestamp(startTimeInSeconds)
    if (eventsGroupedByHalfHourWindows[startTimeWindow]) {
      eventsGroupedByHalfHourWindows[startTimeWindow].push(event)
    } else {
      eventsGroupedByHalfHourWindows[startTimeWindow] = [event]
    }
  })

  return eventsGroupedByHalfHourWindows
}

/**
 * Combine events into format needed for display
 * @param {object} events Event data
 * @param {string} category Category of event (e.g. medicationOrSupplement)
 * @param {string} startTimeWindow Start time of event corresponding to half hour window
 * @param {array} textElements Array of text elements for event
 * @returns {array} Events combined into format needed for display
 */
const combineEventsInFormatNeededForDisplay = ({
  category,
  events,
  startTimeWindow,
  textElements,
}) => {
  const startTimeWindowInSeconds = Number(startTimeWindow)

  events.push({
    classification: {
      category: category,
    },
    duration: {
      startTime: startTimeWindowInSeconds,
      endTime: startTimeWindowInSeconds,
    },
    text: textElements,
    payload: {},
  })

  return events
}

/**
 * Parse payload if it is a string
 * @param {mixed} payload String or object representing event payload
 * @returns {object} Parsed payload
 */
const formatPayload = (payload) =>
  typeof payload === 'string' ? JSON.parse(payload) : payload

/**
 * Generate formatted time string
 * @param {number} timeInSeconds (e.g. 1705417200; 1705701200.494389)
 * @param {string} selectedTimezone the timezone selected in the dropdown
 * @returns {string} Formatted time string (e.g. 15:32)
 */
const generateTimeString = (timeInSeconds, selectedTimezone) => {
  const time = moment.tz(
    timeInSeconds * SECOND_IN_MILLISECONDS,
    selectedTimezone,
  )
  return convertMomentObjectToSpecifiedTimezoneWithFormatting({
    time,
    timezoneName: selectedTimezone,
    format: TIME_FORMAT,
  })
}

/**
 * Consolidate medication/supplement events into half hour windows
 * @param {array} medicationsAndSupplements Array of medication/supplement category events
 * @param {string} selectedTimezone the timezone selected in the dropdown
 * @returns {array} Consolidated events
 */
const combineMedicationAndSupplementEvents = (
  medicationsAndSupplements,
  selectedTimezone,
) => {
  const combinedMedicationAndSupplementEvents = []

  const medicationsAndSupplementsGroupedByHalfHourWindows =
    groupEventsIntoHalfHourWindows(medicationsAndSupplements)

  Object.keys(medicationsAndSupplementsGroupedByHalfHourWindows).forEach(
    (startTimeWindow) => {
      const textElements = []
      let doseText

      medicationsAndSupplementsGroupedByHalfHourWindows[
        startTimeWindow
      ].forEach((event) => {
        const startTimeString = generateTimeString(
          event.duration.startTime,
          selectedTimezone,
        )
        const name = getEventName(event)
        const { payload } = event
        const eventPayload = formatPayload(payload)
        const dosage = eventPayload?.dosage
        const dosageStrengthQuantity = eventPayload?.dosage_strength?.quantity
        const dosageStrengthUnit = eventPayload?.dosage_strength?.unit

        if (dosage && dosageStrengthQuantity) {
          doseText = `${dosage} x ${dosageStrengthQuantity}${dosageStrengthUnit}`
        } else if (!dosage && !dosageStrengthQuantity) {
          doseText = ``
        } else if (dosageStrengthQuantity) {
          doseText = `${dosageStrengthQuantity}${dosageStrengthUnit}`
        } else if (dosage) {
          doseText = `${dosage} pills`
        }

        textElements.push({
          eventText: name,
          eventQuantifier: doseText,
          eventDetails: [startTimeString],
        })
      })

      combineEventsInFormatNeededForDisplay({
        category: 'medicationOrSupplement',
        events: combinedMedicationAndSupplementEvents,
        startTimeWindow,
        textElements,
      })
    },
  )

  return combinedMedicationAndSupplementEvents
}

/**
 * Consolidate symptom/side effect events into half hour windows with the text formatted for display
 * @param {array} symptomsAndSideEffects Array of symptom/side effects category events
 * @param {string} selectedTimezone the timezone selected in the dropdown
 * @returns {array} Consolidated events
 */
export const combineSymptomsAndSideEffects = (
  symptomsAndSideEffects,
  selectedTimezone,
) => {
  const combinedSymptomAndSideEffectEvents = []
  const symptomsAndSideEffectsGroupedByHalfHourWindows =
    groupEventsIntoHalfHourWindows(symptomsAndSideEffects)

  Object.keys(symptomsAndSideEffectsGroupedByHalfHourWindows).forEach(
    (startTimeWindow) => {
      const textElements = []

      symptomsAndSideEffectsGroupedByHalfHourWindows[startTimeWindow].forEach(
        (event) => {
          const startTimeString = generateTimeString(
            event.duration.startTime,
            selectedTimezone,
          )
          const name = getEventName(event)
          const { payload } = event
          const eventPayload = formatPayload(payload)
          const eventDuration = eventPayload?.duration
          const eventDetails = [`Start time: ${startTimeString}`]

          if (eventDuration) {
            eventDetails.push(`Duration: ${eventDuration}`)
          }

          textElements.push({
            eventText: name,
            eventQuantifier: generateIntensityLevelText({
              intensity: eventPayload.intensity,
              includeColon: false,
            }),
            eventDetails,
          })
        },
      )

      combineEventsInFormatNeededForDisplay({
        category: 'symptomOrSideEffect',
        events: combinedSymptomAndSideEffectEvents,
        startTimeWindow,
        textElements,
      })
    },
  )

  return combinedSymptomAndSideEffectEvents
}

/**
 * Put the array of incoming data into date-specific chunks and format the traces
 * @param {array} events The array of event data
 * @param {string} selectedTimezone the timezone selected in the dropdown
 * @returns {object} The object keys correspond to dates. Each date has a value which is an array of objects that include intensity (number, string, or undefined) and plotData (object).
 */
export const formatEventsDataByDay = (events, selectedTimezone) => {
  let eventsFormatted = {}
  // A lot has to happen to this data to be formatted correctly so I've broken it out into two distinct parts - the initial trace creation and then
  // a separate function to determine whether the event spans two dates, and handles it accordingly

  if (events?.length) {
    // Group events by category: (1) medication/supplement, (2) symptom/side effect, (3) other
    const splitEventsByCategory = events.reduce(
      (splitEvents, event) => {
        if (isMedicationOrSupplement(event.classification.category)) {
          splitEvents.medicationOrSupplement.push(event)
        } else if (
          isSymptomOrSideEffect(event.classification.category) &&
          !isOnOffPeriod(event.displayName)
        ) {
          splitEvents.symptomOrSideEffect.push(event)
        } else {
          splitEvents.other.push(event)
        }

        return splitEvents
      },
      { medicationOrSupplement: [], other: [], symptomOrSideEffect: [] },
    )

    const { medicationOrSupplement, other, symptomOrSideEffect } =
      splitEventsByCategory

    const combinedMedicationAndSupplementEvents =
      combineMedicationAndSupplementEvents(
        medicationOrSupplement,
        selectedTimezone,
      )

    const combinedSymptomAndSideEffectEvents = combineSymptomsAndSideEffects(
      symptomOrSideEffect,
      selectedTimezone,
    )

    const remergedEvents = [
      ...combinedMedicationAndSupplementEvents,
      ...other,
      ...combinedSymptomAndSideEffectEvents,
    ]
    remergedEvents.forEach((event) => {
      const { startTime: startTimeInSeconds, endTime: endTimeInSeconds } =
        event.duration
      const startTime = moment.tz(
        startTimeInSeconds * SECOND_IN_MILLISECONDS,
        selectedTimezone,
      )

      const endTime = moment.tz(
        endTimeInSeconds * SECOND_IN_MILLISECONDS,
        selectedTimezone,
      )

      const startDatetimeString =
        convertMomentObjectToSpecifiedTimezoneWithFormatting({
          time: startTime,
          timezoneName: selectedTimezone,
          format: DATETIME_FORMAT,
        })

      const endDatetimeString =
        convertMomentObjectToSpecifiedTimezoneWithFormatting({
          time: endTime,
          timezoneName: selectedTimezone,
          format: DATETIME_FORMAT,
        })

      const startTimeString =
        convertMomentObjectToSpecifiedTimezoneWithFormatting({
          time: startTime,
          timezoneName: selectedTimezone,
          format: TIME_FORMAT,
        })

      const startDateString =
        convertMomentObjectToSpecifiedTimezoneWithFormatting({
          time: startTime,
          timezoneName: selectedTimezone,
          format: DATE_FORMAT,
        })

      const endDateString =
        convertMomentObjectToSpecifiedTimezoneWithFormatting({
          time: endTime,
          timezoneName: selectedTimezone,
          format: DATE_FORMAT,
        })

      const payload =
        typeof event.payload === 'string'
          ? JSON.parse(event.payload)
          : event.payload

      const name = getEventName(event) || ''

      const yAxisLabel = determineYAxisLabelForEvent(event)

      const text = event.text || [
        determineTextForEvent(event, name, payload.intensity, startTimeString),
      ]

      // Baseline trace item
      const eventPlotDataTemplate = {
        plotData: {
          ...PLOT_CONFIG.plotData,
          name: name,
          x: [startDatetimeString, endDatetimeString],
          // the y-value is one value, so that the two x-values map to the same line
          y: [yAxisLabel],
          text: text,
        },
      }

      if (typeof payload.intensity !== 'undefined') {
        eventPlotDataTemplate.intensity = payload.intensity
      }

      // Make sure we have a date to push   to in our array
      if (!eventsFormatted[startDateString]) {
        eventsFormatted[startDateString] = []
      }

      // Duplicate the event so it can be shown on both dates as appropriate
      eventsFormatted = groupEventDataByDay(
        startDateString,
        endDateString,
        eventsFormatted,
        eventPlotDataTemplate,
        startDatetimeString,
        endDatetimeString,
      )
    })
  }
  return eventsFormatted
}

/**
 * @param {object} basePlotData The baseline data to combine into
 * @param {object} additionalPlotData The data to be added
 * @returns {object} The object keys correspond to dates. Each date has a value which is an array of objects that include plotData (object) and sometimes intensity.
 */
export const combineAllPlotDataByDay = (basePlotData, additionalPlotData) => {
  for (let i = 0; i < additionalPlotData.length; i++) {
    Object.entries(additionalPlotData[i]).forEach(([date, entries]) => {
      basePlotData[date] = (basePlotData[date] || []).concat(entries)
    })
  }

  return basePlotData
}

/**
 * Create an array of all the dates between the start and end selected
 * @param {string} endTime The end time
 * @param {string} startTime The start time
 * @param {bool} timezoneNameEnabled Whether timezoneNameEnabled feature flag is active for user
 * @return {array} An array of date strings (e.g. '2021-08-03') in descending order.
 */
export const createArrayOfDatesInDescendingOrder = (
  endTime,
  startTime,
  timezoneNameEnabled = false,
) => {
  let increment = 0
  const dateDiff =
    (dateToTimestamp(endTime, timezoneNameEnabled) -
      dateToTimestamp(startTime, timezoneNameEnabled)) /
    DAY_IN_SECONDS
  const dateArray = []
  while (increment <= dateDiff) {
    dateArray.push(moment(startTime).add(increment, 'days').format(DATE_FORMAT))
    increment++
  }
  return dateArray.reverse()
}

/**
 * Modify the formattedData object so that keys and values are created for days with no data
 * @param {array} dates An array of date strings formatted as 'YYYY-MM-DD'
 * @param {object} formattedData Previously processed data. The object key is a date string and the value is an array of objects that include plotData and intensity (optional)
 * @return {object} The object keys correspond to dates. Each date has a value which is an array of objects that include plotData (object) and sometimes intensity.
 */
export const addDaysWithNoDataToFormattedData = (dates, formattedData) => {
  const nonExistentIndex = -1
  const copyOfFormattedData = { ...formattedData }
  if (formattedData.length !== dates.length) {
    // find missing dates
    const formattedDataDates = Object.keys(formattedData)
    const missingDates = dates.filter(
      (date) => formattedDataDates.indexOf(date) === nonExistentIndex,
    )
    // add day to formattedData and set its data to an empty array
    missingDates.forEach((date) => (copyOfFormattedData[date] = []))
  }
  return copyOfFormattedData
}

/**
 *  In some instances, an array of data will include data from the day before and/or the day after the current day.
    This is because it can include data that began before 12 AM of the current day
    or data that ended after 11:59 PM of the current day.
    This function filters out the data from the previous and next day, along with undefined data.
 * @param {array} data The data that we want to filter
 * @param {string} date The date for which we want the data, e.g. '2023-01-21'
 * @return {array} An array that only includes the items for the specified date
 */
export const returnDataForSpecifiedDate = ({ data, date }) => {
  const dataForSpecifiedDate = data.map((item) => item[date])
  return dataForSpecifiedDate.filter((data) => typeof data !== 'undefined')
}
