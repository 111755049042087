import {
  instanceOf,
  string,
  object,
  array,
  bool,
  func,
  oneOfType,
} from 'prop-types'
import { useEffect, useState } from 'react'
import ChartLoading from 'ui/components/ChartLoading/ChartLoading'
import {
  dateToTimestamp,
  DAY_IN_SECONDS,
  MINUTE_IN_SECONDS,
} from 'utilities/time'
import DayBitmapPlot from '../DayBitmapPlot'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import ErrorFrame from 'ui/components/ErrorFrame/ErrorFrame'
import { formatDataStreamsForEvents } from '../EventsAvailability/EventsAvailabilityMapping'
import { getAvailabilityRequests } from './helpers'
import LoadMoreMonths from 'ui/components/LoadMoreMonths'
import MonthBitmapPlot from '../MonthBitmapPlot'
import MonthBitmapLegend from '../MonthBitmapPlot/MonthBitmapLegend/MonthBitmapLegend'
import { NotificationBar, Warning } from 'ui/components/Notifications'
import { useStreamApiBatch } from 'domains/streamApi'
import { useDataSessions } from 'ui/screens/Patient/Browse/Streams/StreamVisualization/Events/queries.js'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Z_INDEX_EXPLORER_BITMAP_STICKY,
  Z_INDEX_LOADER_SHIM_CLOSED,
  Z_INDEX_LOADER_SHIM_OPEN,
} from 'theme/zIndexRegistry'
import { useFlags } from 'domains/launchdarkly/hooks'

const useStyles = makeStyles((theme) => ({
  loaderShimClosed: {
    backgroundColor: 'hsla(0, 0%, 100%, 0.0)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'background-color 0.2s ease 0s',
    zIndex: Z_INDEX_LOADER_SHIM_CLOSED,
    pointerEvents: 'none',
  },
  loaderShimOpen: {
    backgroundColor: 'hsla(0, 0%, 100%, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'background-color 0.2s ease 0.5s',
    zIndex: Z_INDEX_LOADER_SHIM_OPEN,
  },
  dailyViewPadding: {
    height: theme.spacing(4),
  },
}))

const ExplorerBitmap = ({
  dateStart,
  dateEnd,
  patient,
  devices,
  monthlyView,
  setDateRange,
  onSelect,
}) => {
  const { timezoneNameEnabled } = useFlags()
  const classes = useStyles()
  const [safeDevices, setSafeDevices] = useState([])
  const [deviceWarning, setDeviceWarning] = useState(false)
  const requests = []
  const commonParams = {
    patient_id: patient.id,
    start_time: dateToTimestamp(dateStart),
    end_time: dateToTimestamp(dateEnd) + DAY_IN_SECONDS,
    resolution: DAY_IN_SECONDS,
  }

  const timezone = new Date().getTimezoneOffset() * -60
  const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

  const commonParamsWithTimezone = timezoneNameEnabled
    ? { ...commonParams, timezone_name: timezoneName, timestamp: 'iso' }
    : { ...commonParams, timezone, timestamp: 'datetime' }

  // for daily view, we need to provide a different resolution and the additional partition_size param
  if (!monthlyView) {
    const RESOLUTION = 5 * MINUTE_IN_SECONDS
    commonParamsWithTimezone.resolution = RESOLUTION
    commonParamsWithTimezone.partition_size = DAY_IN_SECONDS / RESOLUTION
  }

  const deviceWarningText =
    'One or more of your devices is disabled. Please enable it to view its data here.'

  useEffect(() => {
    const filteredDevices = devices.filter((x) => x !== undefined) // eslint-disable-line no-undefined
    if (devices.length > filteredDevices.length) {
      setDeviceWarning(true)
    }
    setSafeDevices(filteredDevices)
  }, [devices])

  // Allows the error notification to be manually closed.
  const handleCloseWarning = () => {
    setDeviceWarning(false)
  }

  for (const device of safeDevices) {
    requests.unshift(
      ...getAvailabilityRequests(device, commonParamsWithTimezone),
    )
  }

  // we can't call hooks conditionally, so until we have the dataSessions availability endpoint
  // we'll have to request this indiscriminately. That's okay for now though - when limited to
  // "deviceIds: 'strivestudy'" this is a small request.
  const { data: taskData } = useDataSessions({
    patientId: commonParamsWithTimezone.patient_id,
    startTime: commonParamsWithTimezone.start_time,
    endTime: commonParamsWithTimezone.end_time,
    deviceIds: 'strivestudy',
  })

  const { data, errors, loading } = useStreamApiBatch(requests)

  if (errors && errors.length) {
    return <ErrorFrame />
  }

  // formatting step for events availability data
  const formattedData = formatDataStreamsForEvents(
    data,
    commonParamsWithTimezone,
    taskData,
    monthlyView,
  )

  const generateProto = () => {
    if (formattedData && formattedData[0]) {
      if (formattedData.length > 1) {
        return formattedData.reduce((a, b) => (a.length > b.length ? b : a))
      }
      return formattedData[0]
    }
    return []
  }

  const proto = generateProto()

  // used for the monthly view legend
  const generateStreamNames = () => {
    if (formattedData && formattedData[0]) {
      return formattedData.map((stream) => stream[0].label)
    }
    return []
  }

  const streamNames = generateStreamNames()
  const renderLegend = monthlyView && !loading && !!streamNames
  const renderMonthWidgets =
    renderLegend && moment(dateEnd).diff(moment(dateStart), 'months') < 11

  return (
    <ErrorBoundary>
      <StickyContainer>
        <div
          className={
            loading ? classes.loaderShimOpen : classes.loaderShimClosed
          }
          data-cy="ExplorerBitmap"
        >
          {loading ? <ChartLoading /> : null}
        </div>
        {renderLegend ? (
          <Sticky topOffset={-60}>
            {({ style, isSticky }) => (
              <div
                style={{
                  ...style,
                  marginTop: isSticky ? '60px' : '0px',
                  zIndex: Z_INDEX_EXPLORER_BITMAP_STICKY,
                  borderBottom: isSticky ? '1px solid #CCCBC9' : 'none',
                }}
              >
                <MonthBitmapLegend streamNames={streamNames} />
              </div>
            )}
          </Sticky>
        ) : (
          <div className={classes.dailyViewPadding} />
        )}
        {renderMonthWidgets && (
          <LoadMoreMonths
            direction={'future'}
            from={dateStart}
            to={dateEnd}
            setDateRange={setDateRange}
          />
        )}
        {monthlyView ? (
          <>
            {[...Array(proto.length).keys()].reverse().map((month) => (
              <MonthBitmapPlot
                key={month}
                streams={formattedData.map((streamData) => streamData[month])}
                onSelect={onSelect}
              />
            ))}
          </>
        ) : (
          [...Array(proto.length).keys()].map((day) => (
            <DayBitmapPlot
              key={day}
              streams={formattedData.map((streamData) => streamData[day])}
              onSelect={onSelect}
            />
          ))
        )}
        {renderMonthWidgets && (
          <LoadMoreMonths
            direction={'past'}
            from={dateStart}
            to={dateEnd}
            setDateRange={setDateRange}
          />
        )}
      </StickyContainer>

      <NotificationBar
        open={deviceWarning}
        onClose={handleCloseWarning}
        autoHideDuration={20000}
      >
        <Warning message={deviceWarningText} onClose={handleCloseWarning} />
      </NotificationBar>
    </ErrorBoundary>
  )
}

ExplorerBitmap.propTypes = {
  dateStart: oneOfType([string, instanceOf(Date)]),
  dateEnd: oneOfType([string, instanceOf(Date)]),
  patient: object,
  devices: oneOfType([object, array]),
  monthlyView: bool,
  setDateRange: func,
  onSelect: func,
}

export default ExplorerBitmap
