import PatientNotes from './PatientNotes'
import { MetricsRow } from './MetricsRow'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'

export const OverviewPatientNotes = () => {
  const { id: patientId = '' } = useContext(PatientContext)

  return (
    <MetricsRow
      title="Notes"
      linkHref={`/patients/${patientId}/log`}
      linkText="View All Notes"
      noBorder
    >
      <PatientNotes />
    </MetricsRow>
  )
}
