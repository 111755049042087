import moment from 'moment'
import {
  fmtMomentLocal,
  secondsToMinutes,
  secondsToRoundedMinutesAndSeconds,
} from 'utilities/time'
import { sortBy } from 'lodash'
import { getChartIntervals } from 'ui/clinicianScreens/Patient/Report/helpers'

// TODO: Update to account for timezone if we end up using this.
export const getReportDates = (formatted) => ({
  startDate: moment()
    .subtract(30, 'day')
    .format(formatted ? 'MMMM DD' : 'YYYY-MM-DD'),
  endDate: moment().format(formatted ? 'MMMM DD' : 'YYYY-MM-DD'),
  prevStartDate: moment()
    .subtract(61, 'day')
    .format(formatted ? 'MMMM DD' : 'YYYY-MM-DD'),
  prevEndDate: moment()
    .subtract(31, 'day')
    .format(formatted ? 'MMMM DD' : 'YYYY-MM-DD'),
})

// BAR CHART HELPERS
export const formatBarChartData = (data = {}, label) =>
  data?.xLabels?.map((item, index) => ({
    name: moment(item, 'hh:mm').format('h A'),
    [label]: secondsToMinutes(data?.averaged[index][0].value),
    timestamp: moment(item, 'hh:mm').format('hh:mm A'),
    duration: secondsToRoundedMinutesAndSeconds(data?.averaged[index][0].value),
  }))

// SCATTER CHART HELPERS
// TODO: Check if we need to add timezone if we end up using this.
export const roundToNearestTenMinutes = (momentObj) => {
  const minutes = momentObj.minutes()
  const roundedMinutes = Math.round(minutes / 10) * 10
  return momentObj.clone().minutes(roundedMinutes).seconds(0)
}

// TODO: Update to account for timezone if we end up using this.
export const extractDailySchedules = (data) =>
  data.reduce((result, item) => {
    if (item.scheduleType === 'DAILY') {
      const timesFormatted = item.utcTime.map((time) => {
        const dateBegin = 0
        const dateEnd = 10
        const todaysDate = new Date().toISOString().slice(dateBegin, dateEnd)
        const fullDate = `${todaysDate} ${time}:00`
        const roundedTime = roundToNearestTenMinutes(moment.utc(fullDate))
        return {
          roundedTime: fmtMomentLocal('h:mm A', roundedTime),
          exactTime: fmtMomentLocal('h:mm A', moment.utc(fullDate)),
        }
      })

      result.push(
        ...timesFormatted.map((timeFormatted) => ({
          timestamp: timeFormatted.roundedTime,
          name: item.displayName,
          exactTime: timeFormatted.exactTime,
        })),
      )
    }
    return result
  }, [])

// TODO: Update to account for timezone if we end up using this.
export const formatMedicationScheduleChartData = (data = []) => {
  const chartIntervals = getChartIntervals('h:mm A')

  const formattedData = chartIntervals.map((timestamp) => ({
    timestamp,
    name: moment(timestamp, 'hh:mm A').format('h A'),
    displayNames: [],
    displayObjects: [],
    value: 0,
    index: 0,
  }))
  // Create a map for timestamp to index
  const timestampToIndexMap = new Map(
    chartIntervals.map((timestamp, index) => [timestamp, index]),
  )

  // Iterate over the data array
  data.forEach((dataItem) => {
    // Get the index of the corresponding timestamp from the map
    const index = timestampToIndexMap.get(dataItem?.timestamp)
    // Check if the index is found and valid
    // eslint-disable-next-line no-undefined
    if (index !== undefined) {
      // Update the displayNames array at the found index
      formattedData[index].displayNames.push(dataItem.name)
      formattedData[index].displayObjects.push(dataItem)
      formattedData[index].value = 5
    }
  })

  // Optionally, sort displayNames arrays in each formattedData item
  formattedData.forEach((item) => {
    item.displayNames.sort()
  })
  return formattedData
}

export const extractUniqueMedicationNames = (data) => {
  const sortedData = sortBy(data, 'displayName')
  return Array.from(
    new Set(
      sortedData
        .filter((item) => item.classification.category === 'medication')
        .map((item) => item.displayName),
    ),
  )
}

export const extractUniqueSupplementNames = (data) => {
  const sortedData = sortBy(data, 'displayName')
  return Array.from(
    new Set(
      sortedData
        .filter((item) => item.classification.category === 'supplement')
        .map((item) => item.displayName),
    ),
  )
}

/**
 * Filter the displayNames of each medication object in the array
 * @param {Array} originalArray - The original array of medication objects
 * @param {Array} filterArray - The array of displayNames to filter by
 * @returns {Array} - The filtered array of medication objects
 **/
export const filterMedications = (originalArray, filterArray) => {
  // Convert filterArray to a Set for efficient lookups
  const filterSet = new Set(filterArray)

  // Map over the original array to create a new array
  return originalArray.map((item) => {
    // Efficiently filter the displayNames
    const filteredDisplayNames = item.displayNames.filter((name) =>
      filterSet.has(name),
    )

    // Return a new object with updated properties
    return {
      ...item,
      displayNames: filteredDisplayNames,
      value: filteredDisplayNames.length ? 1 : 0,
    }
  })
}
