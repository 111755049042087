import { useContext } from 'react'
import { Stack } from 'ui/baseComponents'
import {
  PatientSearchInput,
  ViewPreferenceButton,
} from 'ui/screens/Patients/PatientList/PatientTable'
import { UserPHIContext } from 'ui/contexts'

/**
 * Represents the toolbar component with actions a user can take regarding the patient table.
 * @returns {JSX.Element} The rendered toolbar component.
 */
const PatientTableToolbar = ({ searchComponent }) => {
  const phiVisibility = useContext(UserPHIContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={'space-between'}
      spacing={2}
      sx={{ paddingTop: '2rem' }}
    >
      {searchComponent || <PatientSearchInput />}
      {!phiVisibility && <ViewPreferenceButton />}
    </Stack>
  )
}

export default PatientTableToolbar
