import { useContext, useEffect, useMemo, useState } from 'react'
import { Container, Paper, makeStyles } from '@material-ui/core'
import moment from 'moment'

import { MAXIMUM_INTENSITY, MINIMUM_INTENSITY } from './consts'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import {
  combineAllPlotDataByDay,
  formatEventsDataByDay,
  createArrayOfDatesInDescendingOrder,
  addDaysWithNoDataToFormattedData,
} from './helpers/helpers'
import DailyViewsHeader from '../Tabs/TabContentHeaders/Layouts/Tabs/DailyViewsHeader'
import { InfoCard } from 'ui/components/Card'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import DailyPlot from './DailyPlot'
import { useFlags } from 'domains/launchdarkly/hooks'
import { Text } from 'ui/baseComponents/Text'
import useBoundStore from 'domains/zustand/store'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    marginTop: '10px',
  },
  caption: {
    paddingLeft: theme.spacing(1),
  },
  card: {
    boxShadow: 'none',
  },
}))

/**
 * The daily view with comparative charts for a variety of logged items
 *
 * @returns {JSX.Element} Daily View parent component. All Daily View components are descendants of this component.
 */
const Daily = () => {
  const { timezoneNameEnabled } = useFlags()
  const classes = useStyles()

  const { dateRanges, selectedTimezone } = useContext(DateRangeContext)
  const { start: startTime, end: endTime } =
    dateRanges[DATE_RANGE.default.key] || {}
  const [dateArray, setDateArray] = useState([])

  const eventList = useBoundStore((state) => state.eventList)
  const errorEvents = useBoundStore((state) => state.errorEvents)
  const isLoadingEvents = useBoundStore((state) => state.loadingEvents)

  const [formattedData, setFormattedData] = useState({})
  const intensityRange = {
    max: MAXIMUM_INTENSITY,
    min: MINIMUM_INTENSITY,
  }

  useEffect(() => {
    setDateArray(
      createArrayOfDatesInDescendingOrder(
        endTime,
        startTime,
        timezoneNameEnabled,
      ),
    )
  }, [endTime, startTime, timezoneNameEnabled])

  // **************************
  //  EVENTS DATA
  // **************************
  const filteredEvents = useMemo(
    () =>
      eventList.filter(
        (event) =>
          event.classification.category === 'symptom' ||
          event.classification.category === 'side-effect' ||
          event.classification.category === 'medication' ||
          event.classification.category === 'supplement' ||
          event.classification.enum === 'dyskinesia' ||
          event.classification.enum === 'tremor',
      ),
    [eventList],
  )

  const eventsDataFormatted = useMemo(
    () =>
      formatEventsDataByDay(
        filteredEvents,

        selectedTimezone,
      ),
    [filteredEvents, selectedTimezone],
  )

  // Format the data such that it is sorted by date and can be cycled through to put into the plotly
  useEffect(() => {
    if (!isLoadingEvents) {
      const formattedData = combineAllPlotDataByDay(eventsDataFormatted, [])

      const formattedDataWithEmptyDates = addDaysWithNoDataToFormattedData(
        dateArray,
        formattedData,
      )

      setFormattedData(formattedDataWithEmptyDates)
    }
  }, [dateArray, eventsDataFormatted, isLoadingEvents])

  // error state for events plots
  if (errorEvents) {
    return (
      <>
        <DailyViewsHeader />
        <ErrorBoundary>
          <Container component={Paper} className={classes.root} maxWidth="xl">
            <Text component="p" variant="body16">
              {'Something went wrong! Try refreshing the page.'}
            </Text>
          </Container>
        </ErrorBoundary>
      </>
    )
  }

  return (
    <>
      <DailyViewsHeader />
      <Container component={Paper} className={classes.root} maxWidth="xl">
        {dateArray.map((date) => (
          <div key={date}>
            <InfoCard
              data-cy="clinician-daily"
              className={classes.card}
              title={moment(date).format('MMM DD YYYY')}
            >
              <DailyPlot
                formattedData={formattedData[date]}
                intensityRange={intensityRange}
                startDate={date}
              />
            </InfoCard>
          </div>
        ))}
      </Container>
    </>
  )
}

export default Daily
