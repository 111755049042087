import { useState, useEffect } from 'react'
import Activity from './Streams/Activity'
import AdditionalInfo from './AdditionalInfo'
import ChartLoading from 'ui/components/ChartLoading'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { fmtMomentLocal, SECOND_IN_MILLISECONDS } from 'utilities/time'
import Food from './Streams/Food'
import Medication from './Streams/Medication'
import Tasks from './Streams/Tasks'
import Sleep from './Streams/Sleep'
import ShiftButtons from '../ShiftButtons'
import Symptoms from './Streams/Symptoms'
import { useStreamApi } from 'domains/streamApi'
import { useDataSessions } from './queries'
import { Box, Grid, Typography } from '@material-ui/core'

/**
 * The parent for all event streams. Renders each event stream, requests data,
 * standardizes layout, toggles visibility, and manages any shared state.
 *
 * @param {object} patient The patient whose data is being visualized
 * @param {float} tStart The unix start timestamp for the stream, in seconds
 * @param {float} tEnd The unix end timestamp for the stream, in seconds
 * @param {boolean} showSymptoms toggle state of Symptoms event stream
 * @param {boolean} showMedication toggle state of Medication event stream
 * @param {boolean} showFood toggle state of Food event stream
 * @param {boolean} showTasks toggle state of Tasks event stream
 * @param {boolean} showActivity toggle state of Activity event stream
 * @param {boolean} showSleep toggle state of Sleep event stream
 * @param {boolean} standaloneStream toggle display based on overlay vs standalone
 * @param {function} updateTimeRange function that updates the time range of all streams
 * @returns {React.Component} Events component
 */
const Events = ({
  patient,
  tStart,
  tEnd,
  showSymptoms,
  showMedication,
  showFood,
  showTasks,
  showActivity,
  showSleep,
  standaloneStream = false,
  updateTimeRange,
  timezoneNameEnabled,
}) => {
  const [symptomsPoints, setSymptomsPoints] = useState([])
  const [symptomsSpans, setSymptomsSpans] = useState([])
  const [medicationPoints, setMedicationPoints] = useState([])
  const [medicationSpans, setMedicationSpans] = useState([])
  const [foodPoints, setFoodPoints] = useState([])
  const [foodSpans, setFoodSpans] = useState([])
  const [activityPoints, setActivityPoints] = useState([])
  const [activitySpans, setActivitySpans] = useState([])
  const [sleepPoints, setSleepPoints] = useState([])
  const [sleepSpans, setSleepSpans] = useState([])
  const [additionalData, setAdditionalData] = useState()
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
  const { data: taskData } = useDataSessions({
    patientId: patient.id,
    startTime: tStart,
    endTime: tEnd,
    deviceIds: 'strivestudy',
  })

  const {
    data: pointData,
    error: pointError,
    loading: pointLoading,
  } = useStreamApi({
    url: 'event.json',
    params: {
      patient_id: patient.id,
      start_time: tStart,
      end_time: tEnd,
      event: 'patient',
    },
  })

  const {
    data: spanData,
    error: spanError,
    loading: spanLoading,
  } = useStreamApi({
    url: 'span.json',
    params: {
      patient_id: patient.id,
      start_time: tStart,
      end_time: tEnd,
      event: 'patient',
      expression: 'timeline',
    },
  })

  /**
   * If the requests are done loading, sorts data by event type and sets in state.
   */
  useEffect(() => {
    if (!pointLoading && !spanLoading) {
      const symptomsPointsArray = []
      const symptomsSpansObject = { span: [], time: [], z: [] }

      const medicationPointsArray = []
      const medicationSpansObject = { span: [], time: [], z: [] }

      const foodPointsArray = []
      const foodSpansObject = { span: [], time: [], z: [] }

      const activityPointsArray = []
      const activitySpansObject = { span: [], time: [], z: [] }

      const sleepPointsArray = []
      const sleepSpansObject = { span: [], time: [], z: [] }

      pointData?.result?.event?.forEach((pointEvent) => {
        switch (pointEvent.event_type) {
          case 'symptom':
          case 'symptoms':
          case 'side-effect':
            symptomsPointsArray.push(pointEvent)
            break
          case 'medication':
            medicationPointsArray.push(pointEvent)
            break
          case 'food-drink':
            foodPointsArray.push(pointEvent)
            break
          case 'activity':
            activityPointsArray.push(pointEvent)
            break
          case 'sleep':
            sleepPointsArray.push(pointEvent)
            break
          default:
            break
        }
      })

      spanData.result.span.forEach((spanEvent, index) => {
        switch (spanEvent.event_type) {
          case 'symptom':
          case 'symptoms':
          case 'side-effect':
            symptomsSpansObject.span.push(spanEvent)
            symptomsSpansObject.time.push(spanData.result.time[index])
            symptomsSpansObject.z.push(spanData.result.z[index])
            break
          case 'medication':
            medicationSpansObject.span.push(spanEvent)
            medicationSpansObject.time.push(spanData.result.time[index])
            medicationSpansObject.z.push(spanData.result.z[index])
            break
          case 'food-drink':
            foodSpansObject.span.push(spanEvent)
            foodSpansObject.time.push(spanData.result.time[index])
            foodSpansObject.z.push(spanData.result.z[index])
            break
          case 'activity':
            activitySpansObject.span.push(spanEvent)
            activitySpansObject.time.push(spanData.result.time[index])
            activitySpansObject.z.push(spanData.result.z[index])
            break
          case 'sleep':
            sleepSpansObject.span.push(spanEvent)
            sleepSpansObject.time.push(spanData.result.time[index])
            sleepSpansObject.z.push(spanData.result.z[index])
            break
          default:
            break
        }
      })
      setSymptomsPoints(symptomsPointsArray)
      setSymptomsSpans(symptomsSpansObject)

      setMedicationPoints(medicationPointsArray)
      setMedicationSpans(medicationSpansObject)

      setFoodPoints(foodPointsArray)
      setFoodSpans(foodSpansObject)

      setActivityPoints(activityPointsArray)
      setActivitySpans(activitySpansObject)

      setSleepPoints(sleepPointsArray)
      setSleepSpans(sleepSpansObject)
    }
  }, [pointData, pointLoading, spanData, spanLoading])

  // loading state for events plots
  if (pointLoading || spanLoading) {
    return (
      <ErrorBoundary>
        <ChartLoading height={150} />
      </ErrorBoundary>
    )
  }

  // error state for events plots
  if (pointError || spanError) {
    return <ErrorBoundary />
  }

  const localTStart = fmtMomentLocal(
    'Y-MM-DD HH:mm:ss.SSS',
    tStart * SECOND_IN_MILLISECONDS,
    timezoneNameEnabled,
  )
  const localTEnd = fmtMomentLocal(
    'Y-MM-DD HH:mm:ss.SSS',
    tEnd * SECOND_IN_MILLISECONDS,
    timezoneNameEnabled,
  )

  // The layout for each event stream
  const layout = {
    font: { family: 'Work Sans' },
    height: 25,
    margin: { l: 100, r: 100, t: 0, b: 0 },
    showlegend: false,
    visible: true,
    xaxis: {
      automargin: false,
      fixedrange: true,
      range: [localTStart, localTEnd],
      zeroline: true,
      linecolor: '#eeeeee',
      showline: false,
    },
    yaxis: {
      automargin: false,
      fixedrange: true,
      showgrid: true,
      side: 'left',
      zeroline: false,
      zerolinecolor: '#eeeeee',
      showline: false,
      tickcolor: '#ffffff',
      type: 'category',
      visible: true,
      tickfont: {
        size: 11,
      },
    },
  }
  const standaloneLayout = {
    ...layout,
    height: 50,
    margin: { l: 100, r: 100, t: 0, b: 24 },
  }

  /**
   * Sets data to display in the AdditionalInfo component on hover.
   *
   * @param {object} data The point data from the plot
   * @returns {void} void
   */
  const onHover = (data) => {
    const pointData = data.points[0].customdata
    setAdditionalData(pointData)
    setShowAdditionalInfo(true)
  }

  /**
   * Unmounts the AdditionalInfo component
   * @returns {void} void
   */
  const onUnhover = () => {
    setShowAdditionalInfo(false)
  }

  return (
    <ErrorBoundary>
      {standaloneStream && (
        <Typography
          variant="h6"
          fontSize="1.2rem"
          align="center"
          data-cy="eventsTitle"
        >
          Patient Reported Events
        </Typography>
      )}
      {showSleep && (
        <Sleep
          pointData={sleepPoints}
          spanData={sleepSpans}
          layout={layout}
          onHover={onHover}
          onUnhover={onUnhover}
          rangeStartLocal={localTStart}
          rangeEndLocal={localTEnd}
        />
      )}
      {showTasks && (
        <Tasks
          data={taskData}
          layout={layout}
          patient={patient}
          onHover={onHover}
          onUnhover={onUnhover}
          rangeStartLocal={localTStart}
          rangeEndLocal={localTEnd}
        />
      )}
      {showSymptoms && (
        <Symptoms
          pointData={symptomsPoints}
          spanData={symptomsSpans}
          layout={layout}
          onHover={onHover}
          onUnhover={onUnhover}
          rangeStartLocal={localTStart}
          rangeEndLocal={localTEnd}
        />
      )}
      {showMedication && (
        <Medication
          pointData={medicationPoints}
          spanData={medicationSpans}
          layout={layout}
          onHover={onHover}
          onUnhover={onUnhover}
          rangeStartLocal={localTStart}
          rangeEndLocal={localTEnd}
        />
      )}
      {showFood && (
        <Food
          pointData={foodPoints}
          spanData={foodSpans}
          layout={layout}
          onHover={onHover}
          onUnhover={onUnhover}
          rangeStartLocal={localTStart}
          rangeEndLocal={localTEnd}
        />
      )}
      {showActivity && (
        <Activity
          pointData={activityPoints}
          spanData={activitySpans}
          layout={standaloneStream ? standaloneLayout : layout}
          onHover={onHover}
          onUnhover={onUnhover}
          rangeStartLocal={localTStart}
          rangeEndLocal={localTEnd}
        />
      )}
      {standaloneStream && (
        <Box my={2} mx={6}>
          <Grid container item>
            <ShiftButtons
              updateTimeRange={updateTimeRange}
              tStart={tStart}
              tEnd={tEnd}
              eventsStream={true}
            />
          </Grid>
        </Box>
      )}
      <AdditionalInfo data={additionalData} visible={showAdditionalInfo} />
    </ErrorBoundary>
  )
}

export default Events
