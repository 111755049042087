import { useContext, useEffect, useMemo } from 'react'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import { PatientContext } from 'ui/contexts/PatientContext'
import usePatientAdherence from 'ui/hooks/usePatientAdherence'
import { getTimestampEndOfDay, getTimestampStartOfDay } from 'utilities/time'
import MedicationScheduledTables from 'ui/clinicianScreens/Patient/MedicationTables/MedicationScheduledTables.jsx'
import { StyledTitle, Text, Stack } from 'ui/baseComponents'
import { colors } from 'theme/colors'
import MedicationLoggedTables from 'ui/clinicianScreens/Patient/MedicationTables/MedicationLoggedTables'
import Subheading from 'ui/clinicianScreens/Patient/Overview/Subheading'
import { reFetchEventList } from 'ui/screens/Patient/queries'
import { getLoggedAsNeededAndActiveScheduledMeds } from 'ui/clinicianScreens/Patient/helpers'
import usePatientEvents from 'ui/hooks/usePatientEvents'
import { parseUniqueLoggedMedicationByNameAndDosage } from 'ui/clinicianScreens/Patient/MedicationTables/helpers'

const OverviewSidebarContent = () => {
  const { id: patientId } = useContext(PatientContext)
  const { dateRanges, selectedTimezone } = useContext(DateRangeContext)
  const { start: startDate, end: endDate } =
    dateRanges[DATE_RANGE.default.key] || {}
  const startTime = getTimestampStartOfDay(startDate, selectedTimezone)
  const endTime = getTimestampEndOfDay(endDate, selectedTimezone)

  const { dataAdherence } =
    usePatientAdherence(patientId, startTime, endTime) || {}

  const {
    adherencePercentage,
    previousAdherencePercentage,
    nonAdherenceCount,
    adherenceCount,
  } = dataAdherence?.patient?.eventAdherence || {}

  const formattedAdherencePercentage = Math.round(adherencePercentage)
  const adherenceDiff = Number(
    parseFloat(adherencePercentage - previousAdherencePercentage).toFixed(2),
  )

  const { medicationScheduleList } = useContext(PatientContext) || {}
  const { schedules = [] } = medicationScheduleList || {}

  const { dataEvents, fetchMore } = usePatientEvents(
    patientId,
    startTime,
    endTime,
    {
      namespace: 'patient',
      category: 'medication',
      enum: '*',
    },
  )

  const eventList = useMemo(
    () => dataEvents?.patient?.eventList?.events || [],
    [dataEvents],
  )

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = dataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(fetchMore, endCursor)
    }
  }, [dataEvents, fetchMore])

  const { loggedAsNeededMeds } = getLoggedAsNeededAndActiveScheduledMeds(
    schedules,
    eventList,
  )

  const loggedMedicationData = new Map()

  parseUniqueLoggedMedicationByNameAndDosage(
    loggedAsNeededMeds,
    loggedMedicationData,
  )

  const hasScheduledData = schedules?.length > 0
  const hasLoggedData = loggedMedicationData.size > 0

  return (
    <div>
      <Stack>
        <StyledTitle>Current Medication Schedule</StyledTitle>
        {hasScheduledData && (
          <Subheading
            mainText="REPORTED BY PATIENT"
            secondaryText={
              <Text
                color={colors.ORANGE[500]}
                sx={{ borderBottom: '1px dashed', marginRight: '1rem' }}
              >
                Adherence {formattedAdherencePercentage}%
              </Text>
            }
            tooltipContent={
              <>
                {adherenceDiff > 0 && `Up ${adherenceDiff} % vs. prior 2 weeks`}
                {adherenceDiff < 0 &&
                  `Down ${Math.abs(adherenceDiff)}% vs. prior 2 weeks`}
                {adherenceDiff === 0 && `No change vs. prior 2 weeks`}
                <br />
                {adherencePercentage}% Adherence
                <br />
                {nonAdherenceCount} Missed Doses
                <br />
                {adherenceCount} Doses Taken
              </>
            }
          />
        )}
      </Stack>
      <Stack spacing={4}>
        {hasScheduledData ? (
          <MedicationScheduledTables
            {...{
              schedules,
              minimalVersion: true,
            }}
          />
        ) : (
          <Text variant="body16">No medication schedule added</Text>
        )}
        {hasLoggedData && (
          <Stack>
            <Text variant="head18B" component="h3">
              As needed medications logged this period
            </Text>
            <MedicationLoggedTables
              data={loggedMedicationData}
              minimalVersion
            />
          </Stack>
        )}
      </Stack>
    </div>
  )
}

export default OverviewSidebarContent
