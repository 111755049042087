import { useContext } from 'react'
import UserPHIContext from 'ui/contexts/UserPHIContext'
import PatientListClinicianPortal from 'ui/screens/Patients/PatientList/PatientListClinicianPortal'
import PatientListResearcherPortal from 'ui/screens/Patients/PatientList/PatientListResearcherPortal'

/**
 * Renders the PatientList component.
 * This component displays either the PatientListClinicianPortal or the PatientListResearcherPortal
 * based on the phiVisibility context.
 *
 * @returns {JSX.Element} The rendered PatientList component.
 */

const PatientList = () => {
  const phiVisibility = useContext(UserPHIContext)

  if (phiVisibility) {
    return <PatientListClinicianPortal />
  }
  return <PatientListResearcherPortal />
}

export default PatientList
