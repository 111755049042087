import { MetricsRow } from './MetricsRow'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'
import PatientSymptoms from './PatientSymptoms'

export const OverviewPatientSymptoms = () => {
  const { id: patientId } = useContext(PatientContext)

  return (
    <MetricsRow
      title="Symptoms"
      linkHref={`/patients/${patientId}/log`}
      linkText="View Patient Log"
    >
      <PatientSymptoms />
    </MetricsRow>
  )
}
