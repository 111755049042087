const COLUMN_WIDTHS = {
  gapColumn: 200,
  patientNameClinicianPortal: 200,
  patientEmail: 200,
  icon: 20,
}

export const STYLES = {
  emailCell: {
    width: COLUMN_WIDTHS.patientEmail,
  },
  gapColumn: {
    width: COLUMN_WIDTHS.gapColumn,
  },
  iconButton: {
    padding: '3px',
    width: '30px',
    height: '30px',
  },
  iconCell: {
    textAlign: 'center',
    width: COLUMN_WIDTHS.icon,
    padding: '0',
  },
  nameCellClinicianPortal: {
    width: COLUMN_WIDTHS.patientNameClinicianPortal,
  },
  patientName: {
    fontSize: '1.1rem',
    wordBreak: 'break-all',
  },
  settingsIcon: {
    height: '1.5rem',
    padding: 0,
  },
}

export const TABLE_HEADERS = {
  email: 'Email',
  patient: 'Patient',
  report: 'Report',
  settings: 'Settings',
  streams: 'Streams',
}

export const TABLE_STATUS_TEXT = {
  empty: 'No patients found',
  error:
    'There was a problem loading the patient list. Try refreshing the page, or contact support@runelabs.io if the issue persists.',
  search:
    'Search for patients. Enter at least 3 characters for each field you want to search by.',
}

export const MORE_RESULTS_AVAILABLE_TEXT =
  'More results are available. Refine your search to view them.'
