import React from 'react'
import { SECOND_IN_MILLISECONDS, fmtMomentLocal } from 'utilities/time'
import { isShowLoading } from 'ui/screens/Patient/Browse/StreamResults/StreamResult'
import moment from 'moment'
import Plot from 'ui/components/Plot'
import StreamVisualization from './StreamVisualization'
import { useStreamApi } from 'domains/streamApi'
import { useDownloadOptions } from './downloadOptions'

/**
 * Stacked bar chart showing the probability of a patient symptom or condition
 * being observed, broken down into predefined categories of
 * severity/intensity.
 *
 * Symptom probability data is available at regular intervals, and points
 * represent start times of those intervals.
 * @returns {React.Component} ProbSymptomSeverity component
 */
const ProbSymptomSeverity = ({
  device,
  patient,
  query,
  title,
  tStart,
  tEnd,
  updateTimeRange,
  timezoneNameEnabled,
}) => {
  const { symptom } = query
  let { severity: severities } = query

  if (typeof severities === 'string') {
    severities = severities.split(',')
  }
  const SECONDS_IN_MINUTE_FLOAT = 60.0

  const severityLabels = severities.map(
    (sev) => sev.charAt(0).toUpperCase() + sev.slice(1),
  )
  const { data, error, showLoading, revision } = useStreamApi({
    url: 'probability_symptom.json',
    params: {
      ...query,
      device_id: device.id,
      patient_id: patient.id,
      start_time: tStart - SECONDS_IN_MINUTE_FLOAT,
      end_time: tEnd + SECONDS_IN_MINUTE_FLOAT,
      expression: 'probability',
      severity: severities.join(','),
      resolution: null, // @note: downsampling probabilities is ambiguous,
      frequency: null, // disabling for now
    },
    isShowLoading,
  })
  // @note: By always querying +/- 60s around range, we ensure we don't end up
  // *between* the start points of two intervals, where visually it would
  // incorrectly appear as if there's no data.

  const downloadOptions = useDownloadOptions({
    streamName: symptom,
    endpointName: 'probability_symptom',
    patientID: patient.id,
    deviceID: device.id,
    startTime: tStart,
    endTime: tEnd,
    query: {
      ...query,
      expression: 'probability',
      severity: severities.join(','),
      timestamp: 'unix',
    },
  })

  return (
    <StreamVisualization
      device={device}
      downloadOptions={downloadOptions}
      error={error}
      showLoading={showLoading}
      patient={patient}
      title={title}
      tStart={tStart}
      tEnd={tEnd}
      updateTimeRange={updateTimeRange}
      timezoneNameEnabled={timezoneNameEnabled}
    >
      {(defaults) => (
        <Plot
          className={defaults.className}
          config={defaults.config}
          data={severities.map((sev, i) => ({
            hoverinfo: 'none',
            hoverlabel: {
              namelength: 0,
            },
            hovertemplate: `
                <b>${severityLabels[i]}: %{y}</b>
              `,
            line: {
              shape: 'hv',
              width: 0.5,
            },
            mode: 'lines',
            name: severityLabels[i],
            opacity: 1.0,
            type: 'scatter',
            visible: true,
            x: data.result.time,
            y: data.result.probability[sev],
            marker: {
              color: {
                none: 'rgb(148, 187, 218)',
                slight: 'rgb(90, 224, 140)',
                mild: 'rgb(220, 218, 0)',
                moderate: 'rgb(245, 146, 12)',
                strong: 'rgb(245, 47, 0)',
                unknown: 'rgb(240, 240, 240)',
              }[sev],
            },
            stackgroup: 'one',
          }))}
          layout={{
            ...defaults.layout,
            bargap: 0.0,
            barmode: 'stack',
            height: 300,
            hovermode: 'x unified',
            legend: {
              orientation: 'h',
              traceorder: 'reversed',
              y: 1.2,
            },
            margin: {
              ...defaults.layout.margin,
              t: 50,
            },
            showlegend: true,
            xaxis: {
              ...defaults.layout.xaxis,
              hoverformat: ' ',
              range: [
                fmtMomentLocal(
                  'Y-MM-DD HH:mm:ss.SSS',
                  moment(tStart * SECOND_IN_MILLISECONDS),
                  timezoneNameEnabled,
                ),
                fmtMomentLocal(
                  'Y-MM-DD HH:mm:ss.SSS',
                  moment(tEnd * SECOND_IN_MILLISECONDS),
                  timezoneNameEnabled,
                ),
              ],
            },
            yaxis: {
              ...defaults.layout.yaxis,
              fixedrange: true,
              range: [0, 1],
              tickfont: {
                size: 11,
              },
              tickformat: ',.1%',
              title: 'Percentage',
            },
          }}
          onClick={defaults.onClick}
          onInitialized={defaults.onInitialized}
          onRelayout={defaults.onRelayout}
          revision={revision}
          useResizeHandler={defaults.useResizeHandler}
        />
      )}
    </StreamVisualization>
  )
}

export default ProbSymptomSeverity
