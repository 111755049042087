import EditPatient from 'ui/screens/EditPatient'
import { Daily } from '../Daily'
import { Log } from '../Log'
import { Overview } from '../Overview'
import { Report } from '../Report'
import { Summary } from '../Summary'
import { clinicianRoutes } from './consts'
import { StriveGPT } from '../StriveGPT'
import { SHARED_ROUTES } from 'app/AppRoutes/consts'
import { WeeklyView } from 'ui/clinicianScreens/Patient/WeeklyView'

/**
 * Formatting the tabs and their respective necessary props/urls/links
 *
 * @returns {object[]} List of tabs to display
 */
export const getTabs = ({
  comparisonModeEnabled,
  overviewTabVisible,
  monthlyReportsTabVisible,
  settingsTabVisible,
  striveGptVisible,
  clinicianReportVisible,
  weeklyViewTabVisible,
}) => {
  const tabArray = [
    ...(overviewTabVisible
      ? [
          {
            id: clinicianRoutes.OVERVIEW,
            component: <Overview />,
            label: 'Overview',
            to: `${clinicianRoutes.OVERVIEW}`,
          },
        ]
      : []),
    {
      id: clinicianRoutes.SUMMARY,
      component: <Summary />,
      label: 'Detail View',
      to: comparisonModeEnabled
        ? `${clinicianRoutes.SUMMARY_COMPARISON}`
        : `${clinicianRoutes.SUMMARY}`,
    },
    ...(weeklyViewTabVisible
      ? [
          {
            id: clinicianRoutes.WEEKLY_VIEW,
            component: <WeeklyView />,
            label: 'Weekly View',
            to: `${clinicianRoutes.WEEKLY_VIEW}`,
          },
        ]
      : []),
    {
      id: clinicianRoutes.DAILY,
      component: <Daily />,
      label: 'Daily View',
      to: `${clinicianRoutes.DAILY}`,
    },
    {
      id: clinicianRoutes.LOG,
      component: <Log />,
      label: 'Patient Log',
      to: `${clinicianRoutes.LOG}`,
    },
    ...(striveGptVisible
      ? [
          {
            id: clinicianRoutes.ANSWER_QUESTION,
            component: <StriveGPT />,
            label: 'StriveGPT',
            to: `${clinicianRoutes.ANSWER_QUESTION}`,
          },
        ]
      : []),
    ...(!settingsTabVisible
      ? [
          {
            id: clinicianRoutes.EXPLORE,
            // The way the app is currently set up, this component never gets rendered
            component: <></>,
            label: 'Streams',
            to: `${clinicianRoutes.EXPLORE}`,
          },
        ]
      : []),
    ...(monthlyReportsTabVisible
      ? [
          {
            id: clinicianRoutes.REPORT_PATIENT,
            component: <Report />,
            label: 'Patient Report',
            to: `${clinicianRoutes.REPORT_PATIENT}`,
          },
        ]
      : []),
    ...(clinicianReportVisible
      ? [
          {
            id: clinicianRoutes.REPORT_CLINICIAN,
            component: <Report isClinicianReport />,
            label: 'Clinician Report',
            to: `${clinicianRoutes.REPORT_CLINICIAN}`,
          },
        ]
      : []),
    ...(settingsTabVisible
      ? [
          {
            id: clinicianRoutes.SETTINGS,
            component: <EditPatient />,
            label: 'Settings',
            to: `${SHARED_ROUTES.editPatient.path}`,
          },
        ]
      : []),
  ]

  return tabArray
}
